import { Input } from '@material-tailwind/react';
import { Typography } from '@mui/material';

const ContactPerson = ({ customer, updateCustomer, errorMessages }: any) => {
  const Error = ({ message }: any) => {
    if (!message) return null;
    return (
      <div>
        <Typography color="red" className="mb-4 text-md font-bold w-full   rounded-md">
          {message}
        </Typography>
      </div>
    );
  };
  const sx = {
    fontFamily: 'open-sans',
    fontSize: '1rem',
    fontWeight: 600,
    color: '#5c5c5c',
  };

  return (
    <div className="bg-greyBackground p-4 w-full justify-center items-center rounded-md  shadow-md mb-4 py-10 ">
      <Typography
        className="text-almostBlack "
        sx={{
          fontSize: '1.25rem',
          fontWeight: 600,
          color: '#5c5c5c',
          textAlign: 'center',
          padding: '10px',
          fontFamily: 'Poppins',
        }}
      >
        Yhteyshenkilö
      </Typography>
      <div className="flex flex-col items-center  w-full justify-center font-poppins mx-auto">
        <div className="flex flex-col items-center gap-4 justify-center w-full md:w-4/5 mx-auto ease-in-out duration-300">
          <Error message={errorMessages.contactPersonName} />

          <Input
            style={sx}
            value={customer.contactPerson.name}
            crossOrigin="true"
            required
            variant="standard"
            onChange={(e) => updateCustomer('contactPerson', 'name', e.target.value)}
            label="Yhteyshenkilön nimi"
            color="light-blue"
          />

          <Error message={errorMessages.contactPersonEmail} />

          <Input
            style={sx}
            variant="standard"
            type="email"
            crossOrigin="true"
            color="light-blue"
            value={customer.contactPerson.email}
            onChange={(e) => updateCustomer('contactPerson', 'email', e.target.value)}
            label="Sähköposti"
          />

          <Error message={errorMessages.contactPersonPhone} />

          <Input
            style={sx}
            type="text"
            crossOrigin="true"
            variant="standard"
            color="light-blue"
            value={customer.contactPerson.phone}
            onChange={(e) => updateCustomer('contactPerson', 'phone', e.target.value)}
            label="Puhelinnumero"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactPerson;
