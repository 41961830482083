import { PhoneAuthProvider, PhoneMultiFactorGenerator, getMultiFactorResolver, RecaptchaVerifier } from '@firebase/auth';
import { auth } from '../firebase';

import { getUserData } from './apiFunctions';

export const handleMfa = async ({ error, setUser }: { error: any; setUser: React.Dispatch<React.SetStateAction<any>> }) => {
  // Handle MFA

  const resolver = getMultiFactorResolver(auth, error);
  // Ask user which second factor to use.
  if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
    const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container-login', {
      size: 'invisible',
      callback: (response: any) => {},
    });
    if (!recaptchaVerifier) {
      return { user: null, error: 'reCAPTCHA not initialized correctly.' };
    }

    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session,
    };

    const phoneAuthProvider = new PhoneAuthProvider(auth);
    // Send SMS verification code
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);

    // Ask user for SMS verification code
    const verificationCode = window.prompt('Enter the verification code sent to your phone:');
    if (!verificationCode) {
      recaptchaVerifier.clear();
      return { user: null, error: 'Verification code missing.' };
    }
    // Create MFA assertion
    const phoneCredential = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneCredential);
    // Complete sign-in with the multi-factor assertion
    const response = await resolver.resolveSignIn(multiFactorAssertion);
    if (response.user) {
      const user = await getUserData({ currentUser: response.user });
      setUser(user);
      recaptchaVerifier.clear();
      return { user: response.user, userData: user };
    } else {
      return { user: null, error: 'User not found.' };
    }
  } else {
    return { user: null, error: 'Handle other MFA methods' };
  }
};
