import React, { useState } from 'react';
import { Typography } from '@material-tailwind/react';
import { Button, TextField } from '@mui/material';
import { useAuth } from '../../context/authContext';
const ResetPassword = ({ resetDialogOpen, setResetDialogOpen }: any) => {
  const { ResetPassword } = useAuth();
  const [email, setEmail] = useState('');

  const handleResetPassword = () => {
    ResetPassword(email);
    setResetDialogOpen(false);
  };

  return (
    <div className="w-full h-full flex justify-center flex-col items-center  p-10">
      <div className=" flex flex-col w-full h-full justify-center  items-center">
        <Typography
          placeholder={''}
          color="gray"
          variant="h6"
          className="p-6 pt-0"
        >
          Anna sähköpostiosoitteesi, niin lähetämme sinulle linkin salasanan
          nollaamiseksi.
        </Typography>
        <TextField
          type="email"
          label="Sähköposti"
          variant="standard"
          style={{ transition: 'all .15s ease', width: '80%' }}
          onChange={(e) => setEmail(e.target.value)}
        />

        <div className="flex  w-full justify-center items-center gap-6 hover:scale-105 ease-in-out duration-300 pt-10">
          <Button
            variant="contained"
            sx={{
              minWidth: '200px',
              padding: '10px 20px',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontFamily: 'poppins',
              borderRadius: 30,
              fontWeight: 600,

              color: 'white',
              backgroundColor: '#43687e',
              '&:hover': {
                backgroundColor: '#2c4f5a',
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              handleResetPassword();
            }}
          >
            Lähetä linkki
          </Button>
          {/*     <Button
          variant="contained"
          sx={{
            borderRadius: 6,
            padding: '10px',
            width: '60%',
          }}
          onClick={(e) => {
            e.preventDefault();
            setResetDialogOpen(false);
          }}
        >
          peruuta
        </Button> */}
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
