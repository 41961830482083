import React from 'react';
import { Typography } from '@mui/material';
const Modal = ({ show, onClose, children, title }: { show: boolean; onClose: () => void; children: React.ReactNode; title?: string }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50 overflow-y-auto " onClick={onClose}>
      <div
        className="flex flex-col p-4 md:p-5 border w-auto min-h-[300px]  items-center justify-center max-w-[800px]  shadow-lg rounded-md bg-white transform transition-all"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex w-full justify-between items-start ">
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '1rem',
              fontWeight: 600,
              color: '#5c5c5c',
              letterSpacing: -0.3,
            }}
          >
            {title?.toUpperCase()}
          </Typography>
          <button
            onClick={onClose}
            className="text-red-400 text-xl hover:text-gray-600 duration-600 border-radius-50 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50 p-2 w-8 h-8 flex items-center justify-center rounded-full hover:bg-red-100 hover:scale-110 transition ease-in-out duration-300"
          >
            <span className="sr-only">Close</span>
            &#10005; {/* This is a simple cross icon */}
          </button>
        </div>
        <div className="flex flex-1 items-center justify-center p-2 md:p-0">
          <div className="w-full h-full ">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
