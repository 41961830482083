import { useEffect, useState } from 'react';
import { getSites } from '../../database/customerDB';
import { useAuth } from '../../context/authContext';
import { Customer } from '../../../../BACKEND/src/api/types/types';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
interface SubscriptionPlan {
  active: boolean;
  name: string;
  price: number;
  interval: string;
  interval_count: number;
  trial_period_days: number;
  trial_end: number;
  created: number;
}

interface SubscriptionData {
  id: string;
  plan: SubscriptionPlan;
  created: string;
  current_period_start: string;
  current_period_end: string;
}

interface Site {
  Customer: Customer; // Assuming Customer type is defined elsewhere
  cameras: any[];
  subscription: string;
  owner: string;
  x08: string;
  x09: string;
  payment?: SubscriptionData;
}

const Subscriptions = () => {
  const { currentUser } = useAuth();
  // eslint-disable-next-line
  const [userSites, setUserSites] = useState<any[]>([]);
  const [subscriptionsData, setSubscriptionsData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [updateTrigger, setUpdateTrigger] = useState(0);

  useEffect(() => {
    if (!currentUser) {
      setUserSites([]);
      setSubscriptionsData([]);
      setLoading(false);
      return;
    }
    setLoading(true);

    const fetchSitesAndSubscriptions = async () => {
      try {
        const sites = await getSites({ currentUser });
        setUserSites(sites);

        const subscriptionIds = sites
          .filter((site: Site) => site.subscription)
          .map((site: Site) => site.subscription);

        const subscriptions = await Promise.all(
          subscriptionIds.map((id: any) =>
            fetch(
              process.env.REACT_APP_BACKEND_URL + `get-subscription/${id}`
            ).then((res) => res.json())
          )
        );
        const combinedData = sites.map((site: any) => {
          const subscriptionData = subscriptions.find(
            (sub: any) => sub.id === site.subscription
          );

          return {
            ...site,
            payment: subscriptionData,
          };
        });

        setSubscriptionsData(combinedData);
      } catch (err) {
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchSitesAndSubscriptions();
  }, [currentUser, updateTrigger]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!currentUser) return <h1>Not logged in</h1>;
  const refetch = () => {
    setUpdateTrigger((prev) => prev + 1);
  };
  return (
    <div>
      {subscriptionsData.map((sub, index) => {
        return (
          <div key={index} className="">
            <SubDiv sub={sub} refetch={refetch} />
          </div>
        );
      })}
    </div>
  );
};

export default Subscriptions;

const SubDiv = ({ sub, refetch }: { sub: any; refetch: any }) => {
  /*   const handleCancelSubscription = async (subid: string) => {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + `cancel-subscription/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ subid }),
      }
    );
  };
 */
  const handleReFetch = async () => {
    refetch();
  };

  const deleteCamera = async (camera: any) => {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + `/delete-camera/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          camera: camera.id,
          subID: sub.subscription,
          customer: Number(sub.targetID),
        }),
      }
    );

    if (response.status === 200) {
      handleReFetch();
    }
  };

  function toDate(date: any) {
    return new Date(date * 1000).toLocaleDateString('fi-FI');
  }
  const { payment } = sub;
  if (!payment) return null;
  return (
    <div className="p-6 border border-gray-200 rounded-lg shadow divide-y divide-gray-200">
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="space-y-2">
          <p className="font-bold text-lg">Site Information</p>
          <p>
            Name: <span className="text-gray-600">{sub.basicInfo.name}</span>
          </p>
          <p>
            Address:{' '}
            <span className="text-gray-600">{sub.basicInfo.street}</span>
          </p>
        </div>
        <div className="space-y-2">
          <p className="font-bold text-lg">Subscription Details</p>
          <p>
            Status:{' '}
            {payment.plan.active ? (
              <ThumbUpAltIcon className="text-green-500" />
            ) : (
              <ThumbDownIcon className="text-red-500" />
            )}
          </p>
          <p>
            Started:{' '}
            <span className="text-gray-600">{toDate(payment.created)}</span>
          </p>
          <p>
            Next Payment:{' '}
            <span className="text-gray-600">
              {toDate(payment.current_period_end)}
            </span>
          </p>
        </div>
      </div>
      <div className="pt-6">
        <p className="font-bold text-lg mb-2">Cameras ({sub.cameras.length})</p>
        <table className="list-disc list-inside space-y-1">
          <thead>
            <tr>
              <th>ID</th>
              <th>Location</th>
              <th>edit</th>
            </tr>
          </thead>
          <tbody>
            {sub.cameras.map((camera: any, index: any) => (
              <tr key={index} className="text-gray-600">
                <td>{camera.name}</td> {/* Assuming camera.name is the ID */}
                <td>{camera.location}</td>
                <td>
                  <div
                    onClick={() => deleteCamera(camera)}
                    className="text-blue-500"
                  >
                    delete
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
