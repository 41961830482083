import UKK from './ukk';
import { useState, useEffect, useRef } from 'react';
import JoinFullIcon from '@mui/icons-material/JoinFull';
import { Typography } from '@mui/material';
import SupportedDevices from './supportedDevices';
import Guides from './guides';
import { useSearchParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Diagram from '../../components/diagram/diagram';
const PropertiesMain = () => {
  const [selected, setSelected] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isFadingIn, setIsFadingIn] = useState(true); // Aloitetaan true:na ensimmäiselle animaatiolle
  const [renderedContent, setRenderedContent] = useState(selected);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const [searchParams] = useSearchParams();
  const guides = searchParams.getAll('guides');
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const pages = [
    {
      name: 'Alkuun',
      component: (
        <div className="w-full h-full" style={{ aspectRatio: '3 / 2' }}>
          <Diagram />
        </div>
      ),
    },
    { name: 'Tuetut laitteet', component: <SupportedDevices /> },
    { name: 'Ohjeet', component: <Guides searchParams={guides} /> },
    { name: 'UKK', component: <UKK /> },
  ];

  useEffect(() => {
    if (guides.length > 0 && selected !== 2) {
      setSelected(2);
      setRenderedContent(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Keskitetään valitun elementin skrollaus sivupalkissa
    if (sidebarRef.current) {
      const selectedItem = sidebarRef.current.children[selected] as HTMLElement;
      if (selectedItem) {
        const sidebarHeight = sidebarRef.current.clientHeight;
        const itemHeight = selectedItem.clientHeight;
        const initialScroll = selectedItem.offsetTop - (sidebarHeight / 2 - itemHeight / 2);
        sidebarRef.current.scrollTop = initialScroll;
      }
    }
  }, [selected]);

  useEffect(() => {
    // Animaation kesto ensimmäisen renderöinnin jälkeen
    if (isFadingIn) {
      const timer = setTimeout(() => {
        setIsFadingIn(false);
      }, 500); // 1 sekunnin kesto
      return () => clearTimeout(timer);
    }
  }, [isFadingIn]);

  const handleChangeContent = (index: number) => {
    if (index === selected) return;

    setIsFadingOut(true);

    setTimeout(() => {
      setRenderedContent(index);
      setIsFadingOut(false);
      setIsFadingIn(true);
    }, 600); // Animaation kesto (1s)

    setSelected(index);
  };

  return (
    <div className="flex flex-col lg:flex-row w-full justify-center max-w-[1300px] gap-4 lg:h-full items-center">
      <Navigation pages={pages} selected={selected} handleChangeContent={handleChangeContent} sidebarRef={sidebarRef} isMobile={isMobile} />
      <RenderContent pages={pages} renderedContent={renderedContent} isFadingOut={isFadingOut} isFadingIn={isFadingIn} isMobile={isMobile} />
    </div>
  );
};

export default PropertiesMain;

const RenderContent = ({
  pages,
  renderedContent,
  isFadingOut,
  isFadingIn,
  isMobile,
}: {
  pages: { name: string; component: JSX.Element }[];
  renderedContent: number;
  isFadingOut: boolean;
  isFadingIn: boolean;
  isMobile: boolean;
}) => {
  return (
    <div id="content" className="w-[90%] lg:w-4/5 h-full justify-center items-center flex overflow-y-auto scrollbar-hide">
      <div
        className="w-full items-center justify-center flex flex-col gap-2"
        style={{
          opacity: isFadingOut ? 0 : isFadingIn ? 0 : 1,
          transform: isFadingIn ? 'translateY(-100%)' : 'translateY(0)',
          transition: isFadingOut ? 'opacity 0.5s ease' : isFadingIn ? 'opacity 0.5s ease, transform 0.5s ease' : 'opacity 0.5s ease, transform 0.5s ease',
        }}
        key={renderedContent}
      >
        {pages[renderedContent].component}
      </div>
    </div>
  );
};

const Navigation = ({
  pages,
  selected,
  handleChangeContent,
  sidebarRef,
  isMobile,
}: {
  pages: { name: string; component: JSX.Element }[];
  selected: number;
  handleChangeContent: (index: number) => void;
  sidebarRef: React.RefObject<HTMLDivElement>;
  isMobile: boolean;
}) => {
  return (
    <div id="top" className="flex lg:h-full  items-center">
      <div id="sidebar" ref={sidebarRef} className="w-full lg:w-64 lg:h-[300px] relative flex flex-row lg:flex-col items-center">
        {pages.map((page, index) => (
          <div
            key={index}
            className={`lg:absolute lg:top-[130px] left-0 w-full lg:p-2 items-center cursor-pointer transition-all duration-500 ease-in-out flex flex-row gap-2 ${
              selected === index ? 'text-white rounded-md underline' : 'text-black hover:bg-primaryBlue hover:text-white hover:rounded-md'
            }`}
            style={{
              transform: !isMobile ? `translateY(${(index - selected) * 40}px)` : `translateX(${(index - selected) * 10}px)`,
              transition: 'transform 0.5s ease-in-out',
            }}
            onClick={() => handleChangeContent(index)}
          >
            <JoinFullIcon
              sx={{
                fontSize: 20,
                color: selected === index ? 'white' : '#52525b',
                transform: `rotate(${selected !== index ? '90deg' : '0'})`,
                transition: 'transform 0.5s ease-in-out',
                marginRight: '5px',
              }}
            />
            <Typography
              sx={{
                fontFamily: 'poppins',
                fontSize: isMobile ? '0.85rem' : '1.15rem',
                fontWeight: isMobile ? 400 : 500,
                color: selected === index ? 'white' : '#52525b',
              }}
            >
              {page.name}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};
