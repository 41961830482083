import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
const PrivacyPolicy = () => {
  const title = {
    fontFamily: 'poppins',
    fontSize: '1.2rem',
    fontWeight: 600,
    color: '#5c5c5c',
    letterSpacing: -0.3,
  };
  const paragraph = {
    fontFamily: 'open-sans',
    fontSize: '0.875rem',
    fontWeight: 500,
    color: '#5c5c5c',
    letterSpacing: -0.3,
    paddingLeft: '1rem',
    justifyContent: 'center',
  };

  return (
    <div className="w-full items-center justify-center flex">
      <div className=" gap-3 justify-center flex flex-col w-[90%] bg-white rounded-2xl my-4 p-6">
        <Typography sx={title}>Yksityisyydensuojakäytäntö</Typography>
        <Typography sx={paragraph}>
          Yksityisyydensuojakäytäntö Tervetuloa OBSCloud yksityisyydensuojakäytäntöön. Sitoudumme suojelemaan ja kunnioittamaan yksityisyyttäsi. Tämä käytäntö selittää, miten keräämme, käytämme,
          säilytämme ja suojaamme henkilötietojasi kun käytät OBSCloud palveluitamme.
        </Typography>
        <Typography sx={title}> Henkilötietojen kerääminen</Typography>
        <Typography sx={paragraph}>
          Kun rekisteröidyt kuukausitilaukseemme, saatamme kerätä seuraavia tietoja:
          <li>Henkilökohtaiset tiedot: nimi, osoite, sähköpostiosoite ja puhelinnumero.</li>
          <li>Maksutiedot: pankkikortin numero, voimassaoloaika, CVV-koodi ja laskutusosoite.</li>
        </Typography>
        <Typography sx={title}>Henkilötietojen käyttö</Typography>
        <Typography sx={paragraph}>
          Käytämme keräämiämme henkilötietoja seuraaviin tarkoituksiin:
          <li>Kuukausitilauksen hallinta: käytämme henkilötietojasi tilauksesi käsittelyyn, maksujen suorittamiseen ja laskutukseen.</li>
          <li>Asiakaspalvelu: käytämme henkilötietojasi vastataksemme kysymyksiisi ja ratkaistaksemme ongelmasi.</li>
          <li>Markkinointi: käytämme henkilötietojasi lähettääksemme sinulle markkinointiviestejä, jotka saattavat kiinnostaa sinua.</li>
        </Typography>
        <Typography sx={title}>Henkilötietojen jakaminen</Typography>
        <Typography sx={paragraph}>
          Emme jaa pankkikorttitietojasi kolmansille osapuolille, lukuun ottamatta maksujen käsittelyyn tarvittavia maksupalveluntarjoajia. Kaikki kolmannet osapuolet, joiden kanssa jaamme
          henkilötietojasi, ovat velvollisia suojelemaan tietojasi ja käyttämään niitä vain sopimuksen mukaisesti.
        </Typography>
        <Typography sx={title}>Henkilötietojen suojaaminen</Typography>
        <Typography sx={paragraph}>
          Käytämme alan standardeja vastaavia turvatoimia, mukaan lukien SSL-salaus, suojataksemme henkilötietojasi ja erityisesti pankkikorttitietojasi. Vaikka pyrimme käyttämään kaupallisesti
          hyväksyttäviä keinoja suojataksemme tietojasi, mikään siirto Internetin kautta tai elektroninen säilytys ei ole 100% turvallista.
        </Typography>
        <Typography sx={title}>Käyttäjien oikeudet</Typography>
        <Typography sx={paragraph}>
          Sinulla on oikeus pyytää kopio henkilötiedoistasi, jotka meillä on hallussamme, oikaista virheelliset tiedot, rajoittaa tietojen käsittelyä tai poistaa henkilötietosi kokonaan. Voit myös
          vastustaa henkilötietojesi käsittelyä tai pyytää tietojen siirtoa toiselle organisaatiolle. Nämä oikeudet ovat voimassa sovellettavien lakien mukaisesti.
        </Typography>
        <Typography sx={title}>Evästeet</Typography>
        <Typography sx={paragraph}>
          Käytämme evästeitä parantaaksemme käyttökokemustasi. Evästeet ovat pieniä tekstitiedostoja, jotka tallennetaan tietokoneellesi, kun käytät verkkosivustoamme. Käytämme evästeitä
          tunnistaaksemme sinut kun palaat verkkosivustollemme ja personoidaksemme sisältöä ja mainoksia sinulle. Voit estää evästeiden tallentamisen muuttamalla selaimesi asetuksia.
        </Typography>
        <Typography sx={title}>Muutokset yksityisyydensuojakäytäntöön</Typography>
        <Typography sx={paragraph}>
          Pidätämme oikeuden päivittää tätä yksityisyydensuojakäytäntöä tarvittaessa. Suosittelemme, että tarkistat tämän sivun säännöllisesti mahdollisten muutosten varalta. Tämä
          yksityisyydensuojakäytäntö on viimeksi päivitetty 28.9.2024.
        </Typography>
        <Typography sx={title}>Yhteydenotto</Typography>
        <Typography sx={paragraph}>
          Jos sinulla on kysymyksiä tästä yksityisyydensuojakäytännöstä, ota yhteyttä meihin{' '}
          <Link
            to="/contact"
            className="
        text-blue-500 hover:text-blue-700 duration-300 hover:underline
        "
          >
            yhteydenottosivulla
          </Link>
          .
        </Typography>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
