import React, { useState } from 'react';
import { Input } from '@material-tailwind/react';
import { useApi } from '../../../context/ApiCalls';
const AddNewUser = ({ site, closeModal, handleUpdate, setHandleUpdate }: any) => {
  const [remoteUser, setRemoteUser] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const { addRemoteUser } = useApi();

  const handleNewUser = async () => {
    const response = await addRemoteUser({
      remoteUser: remoteUser,
      siteID: site.siteID,
    });
    if (response) {
      setHandleUpdate(handleUpdate + 1);
    }
    closeModal();
  };
  const sx = {
    fontFamily: 'open-sans',
    fontSize: '1rem',
    fontWeight: 600,
    color: '#5c5c5c',
    width: 270,
  };
  return (
    <div className="flex flex-col w-full justify-center items-center gap-8 p-4">
      <Input crossOrigin={true} type="text" label="Nimi" onChange={(e) => setRemoteUser({ ...remoteUser, name: e.target.value })} color="light-blue" style={sx} variant="standard" />
      <div className="w-full flex gap-5 ">
        <Input crossOrigin={true} type="text" label="Sähköposti" onChange={(e) => setRemoteUser({ ...remoteUser, email: e.target.value })} color="light-blue" style={sx} variant="standard" />
        <Input crossOrigin={true} type="text" label="Puhelin" onChange={(e) => setRemoteUser({ ...remoteUser, phone: e.target.value })} color="light-blue" style={sx} variant="standard" />
      </div>

      <div className="flex gap-5">
        <p className="text-xs text-center">
          Käyttäjä saa sähköpostiinsa etäkäyttötunnukset ja ohjeet kameroidan katselemiseen. Käyttäjätunnukset on käyttäjäkohtaisia ja näitä ei luovuteta kolmansille osapuolille. Käyttäjätunnuksen
          lisääminen päivittää automaattisesti rekisteriselosteen.
        </p>
      </div>
      <button
        style={{
          fontFamily: 'poppins',

          fontWeight: 600,
          color: 'white',
        }}
        className="bg-blueBackground text-sm w-3/5  py-2 rounded-3xl hover:bg-primary hover:scale-110 ease-in-out duration-300"
        onClick={handleNewUser}
      >
        Lisää etäkäyttäjät
      </button>
    </div>
  );
};

export default AddNewUser;
