import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import AddHomeIcon from '@mui/icons-material/AddHome';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HomeIcon from '@mui/icons-material/Home';
import { useMediaQuery } from 'react-responsive';
import MobileNavBar from './navbar/mobileNavBar';
import { DashboardLink } from './sidebar/dashboardlink';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import Logo from '../components/svg/logo';
export type DashboardLinks = {
  to: string;
  text: string;
  icon: JSX.Element;
};

const dashboardLinks = [
  { to: '/dashboard', text: 'Hallintapaneeli', icon: <MapsHomeWorkIcon />, role: ['USER', 'ADMIN'] },
  { to: '/addNewCustomer', text: 'Uusi kohde', icon: <AddHomeIcon />, role: ['USER', 'ADMIN'] },
  // { to: '/guides', text: 'Ohjeet', icon: <AutoStoriesIcon /> },
  { to: '/myAccount', text: 'Minun tili', icon: <ManageAccountsIcon /> },
] as DashboardLinks[];

const iconSize = {
  sm: 20,
  md: 20,
  lg: 25,
  xl: 25,
};

function NavigationBar() {
  const { logout, currentUser, user } = useAuth();
  const [startAgain, setStartAgain] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 570 });
  const isWideScreen = useMediaQuery({ maxWidth: 1200 });

  function handleLogout() {
    logout();
    navigate('/login');
  }

  if (isMobile) {
    return <MobileNavBar dashboardLinks={dashboardLinks} user={user} logout={logout} />;
  }

  // const isAdmin = user?.role === 'ADMIN';
  return (
    <div className="font-poppins tracking-one text-white font-medium sticky top-0 flex py-3 z-50 justify-evenly items-center" style={{ zIndex: 9999 }}>
      <div className="w-1/6 items-center">
        {user && !isWideScreen && <p className=" text-nowrap ">Hei, {user.name ? user.name : user.companyName}</p>}
        {!user && !isWideScreen && <Logo startAgain={startAgain} setStartAgain={setStartAgain} type="row" size={200} />}
      </div>
      <div className="flex items-center gap-4" onClick={() => setStartAgain(true)}>
        <DashboardLink to="/home" text="Etusivu" icon={<HomeIcon sx={{ fontSize: iconSize }} />} />
        <DashboardLink to="/about" text="Tietoa meistä" icon={<HomeIcon sx={{ fontSize: iconSize }} />} />
        <DashboardLink to="/properties" text="Ominaisuudet" icon={<HomeIcon sx={{ fontSize: iconSize }} />} />
        <DashboardLink to="/contact" text="Ota yhteyttä" icon={<HomeIcon sx={{ fontSize: iconSize }} />} />

        {currentUser ? (
          <Tooltip title="Kirjaudu ulos" arrow>
            <IconButton onClick={handleLogout} className="flex text-xs lg:text-base items-center hover:scale-110 transition-transform duration-300">
              <LogoutIcon sx={{ fontSize: iconSize, color: 'white' }} />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            <Tooltip title="Kirjaudu sisään" arrow>
              <IconButton onClick={() => navigate('/login')} className="flex text-xs lg:text-base items-center hover:scale-110 transition-transform duration-300">
                <LoginIcon sx={{ fontSize: iconSize, color: 'white' }} />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
}

export default NavigationBar;
/* 
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import AddHomeIcon from '@mui/icons-material/AddHome';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HomeIcon from '@mui/icons-material/Home';
import { useMediaQuery } from 'react-responsive';
import MobileNavBar from './navbar/mobileNavBar';
import { DashboardLink, handleDashboardClick } from './sidebar/dashboardlink';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import logo from '../components/svg/logo';
import Logo from '../components/svg/logo';
export type DashboardLinks = {
  to: string;
  text: string;
  icon: JSX.Element;
};

const dashboardLinks = [
  { to: '/dashboard', text: 'Hallintapaneeli', icon: <MapsHomeWorkIcon />, role: ['USER', 'ADMIN'] },
  { to: '/addNewCustomer', text: 'Uusi kohde', icon: <AddHomeIcon />, role: ['USER', 'ADMIN'] },
  // { to: '/guides', text: 'Ohjeet', icon: <AutoStoriesIcon /> },
  { to: '/myAccount', text: 'Minun tili', icon: <ManageAccountsIcon /> },
] as DashboardLinks[];

const iconSize = {
  sm: 20,
  md: 20,
  lg: 25,
  xl: 25,
};

function NavigationBar() {
  const { logout, currentUser, user } = useAuth();
  const [startAgain, setStartAgain] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 570 });
  const isWideScreen = useMediaQuery({ maxWidth: 1200 });

  function handleLogout() {
    logout();
    navigate('/login');
  }

  function handleLinkClick(destination: string) {
    setStartAgain(true); // Käynnistää animaation uudelleen
    navigate(destination);
  }

  if (isMobile) {
    return <MobileNavBar dashboardLinks={dashboardLinks} user={user} logout={logout} />;
  }

  return (
    <div className="font-poppins tracking-one text-white font-medium sticky top-0 flex py-3 z-50 justify-evenly items-center" style={{ zIndex: 9999 }}>
      <div className="w-1/6 items-center">
        {user && !isWideScreen && <p className="text-nowrap">Hei, {user.name ? user.name : user.companyName}</p>}
        {!user && !isWideScreen && <Logo startAgain={startAgain} setStartAgain={setStartAgain} type="row" size={200} />}
      </div>
      <div className="flex items-center gap-4">
        <button onClick={() => handleLinkClick('/home')}>Etusivu</button>
        <button onClick={() => handleLinkClick('/about')}>Tietoa meistä</button>
        <button onClick={() => handleLinkClick('/properties')}>Ominaisuudet</button>
        <button onClick={() => handleLinkClick('/contact')}>Ota yhteyttä</button>

        {currentUser ? <button onClick={handleLogout}>Kirjaudu ulos</button> : <button onClick={() => handleLinkClick('/login')}>Kirjaudu sisään</button>}
      </div>
    </div>
  );
}

export default NavigationBar;
 */
