import { CamerasTable, RemoteUsersTable, UserInformationTable, ContactInformationTable } from './tables';
import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
const Typo = ({ text, sx }: any) => {
  return (
    <Typography
      sx={{
        ...sx,
        fontFamily: sx?.fontFamily || 'Poppins',
        fontSize: sx?.fontSize || '1rem',
        fontWeight: sx?.fontWeight || 600,
        color: sx?.color || '#5c5c5c',

        textAlign: sx?.textAlign || 'end',
      }}
    >
      {text}
    </Typography>
  );
};
const CheckCustomerData = ({ customer, errors }: any) => {
  const showErrorIndicator = (field: any) => (errors && errors[field] ? <span className="text-red-500">*</span> : null);
  const totalPrice = customer.cameras.length * 19;
  return (
    <div className=" gap-6 max-w-4xl p-4 md:p-8 lg:p-12 rounded-2xl bg-greyBackground ">
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: '1.25rem',
          fontWeight: 600,
          color: '#5c5c5c',
        }}
        className="font-semibold mb-6 text-center"
      >
        Tarkista kohteen tiedot
      </Typography>

      <div className="overflow-x-auto">
        <UserInformationTable customer={customer} errors={errors} showErrorIndicator={showErrorIndicator} />
        <ContactInformationTable customer={customer} />

        <CamerasTable customer={customer} errors={errors} showErrorIndicator={showErrorIndicator} />
        <RemoteUsersTable customer={customer} errors={errors} showErrorIndicator={showErrorIndicator} />

        <div className="w-full grid grid-rows-3 grid-cols-3  ">
          <Typo text={`Kamerat  `} />
          <Typo sx={{ fontWeight: 400, color: '#5c5c5c' }} text={`${customer.cameras.length} kpl x 19,00€`} />
          <Typo text={` ${totalPrice.toFixed(2)} €/kk`} />

          <Typo text={`ALV 25,5 %`} />
          <div></div>

          <Typo text={`${(totalPrice * 0.255).toFixed(2)} €/kk`} />
          <Divider
            sx={{
              color: '#5c5c5c',
              width: '100%',
              paddingTop: 1,
              margin: '0 auto',
            }}
          />
          <Divider
            sx={{
              color: '#5c5c5c',
              width: '100%',
              paddingTop: 1,
              margin: '0 auto',
            }}
          />
          <Divider
            sx={{
              color: '#5c5c5c',
              width: '100%',
              paddingTop: 1,
              margin: '0 auto',
            }}
          />
          <Typo text={'YHTEENSÄ'} />
          <div></div>

          <Typo text={`${(totalPrice * 1.255).toFixed(2)} €/kk`} />
        </div>

        <Typography
          sx={{
            fontFamily: 'open-sans',
            fontSize: '0.75rem',
            fontWeight: 500,
            color: '#5c5c5c',
            textAlign: 'center',
            width: '100%',
            paddingTop: 6,
          }}
        >
          * Hinnat lasketaan kameroiden määrän perusteella
        </Typography>
      </div>
    </div>
  );
};

export default CheckCustomerData;
