type Router = {
  name: string;
  model: string;
  manufacturer: string;
  firmware: string;
  img: string;
  properties: string[];
};
const routers = [
  {
    name: 'M08',
    model: 'RUT M08',
    manufacturer: 'Teltonika',
    firmware: 'RUT9XX_R_00.07.01.1',
    img: 'https://teltonika-networks.com/cdn/extras/21244/rutm08-f-840xAuto.webp',
    properties: ['Ethernet'],
  },
  {
    name: 'M09',
    model: 'RUT M09',
    manufacturer: 'Teltonika',
    firmware: 'RUT9XX_R_00.07.01.1',
    img: 'https://teltonika-networks.com/cdn/extras/24840/rutm09-f-840xAuto.webp',
    properties: ['Ethernet', '4G'],
  },
  {
    name: 'X08',
    model: 'RUT X08',
    manufacturer: 'Teltonika',
    firmware: 'RUT9XX_R_00.07.01.1',
    img: 'https://teltonika-networks.com/cdn/extras/27253/rutx08-black-f-840xAuto.webp',
    properties: ['Ethernet'],
  },
  {
    name: 'X09',
    model: 'RUT X09',
    manufacturer: 'Teltonika',
    firmware: 'RUT9XX_R_00.07.01.1',
    img: 'https://teltonika-networks.com/cdn/extras/27289/rutx09-black-f-840xAuto.webp',
    properties: ['Ethernet', '4G'],
  },
] as Router[];

const Routers = () => {
  return (
    <div className="flex flex-wrap justify-center ">
      {routers.map((router, index) => (
        <div key={index} className="bg-white lg:w-1/3   m-4 rounded-lg drop-shadow-2xl  shadow-primaryBlue">
          <img src={router.img} alt={router.name} className="w-full h-48 object-cover rounded-t-lg" />
          <div className="p-4 gap-2 flex flex-col">
            <h1 className="text-lg font-bold">{router.model}</h1>
            {/* <p className="text-sm">{router.model}</p> */}
            <p className="text-sm">{router.manufacturer}</p>
            {/* <p className="text-xs">{router.firmware}</p> */}
            <div className="flex flex-wrap justify-end">
              {router.properties.map((property, index) => (
                <div key={index} className="bg-gray-200 text-xs m-1 px-2 py-1 rounded-full">
                  {property}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Routers;
