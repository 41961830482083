import React from 'react';
import { Drawer, IconButton, List, ListItem, ListItemText, ListItemIcon, Divider, ListItemButton, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import { DashboardLinks } from '../navigationBar'; // Assuming this is imported correctly
import InfoIcon from '@mui/icons-material/Info';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import Logo from '../svg/logo';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
const MobileNavBar = ({ dashboardLinks, user, logout }: { dashboardLinks: DashboardLinks[]; user: any; logout: () => void }) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setOpen(open);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const isReseller = user?.role === 'RESELLER';

  const RenderLink = ({ to, text, icon }: { to: string; text: string; icon: any }) => {
    return (
      <ListItemButton
        onClick={() => {
          setOpen(false);
          navigate(to);
        }}
      >
        <ListItemIcon className="text-white">{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    );
  };

  return (
    <div className=" text-white font-bold sticky top-0 flex items-center justify-between px-4 py-3 z-10">
      <div className="flex items-center space-x-2">
        {user?.avatar && <Avatar src={user.avatar} alt={user.name} />}
        <span>{user?.name || ''}</span>
      </div>

      <IconButton onClick={toggleDrawer(true)} className="text-white">
        <MenuIcon sx={{ color: 'white' }} />
      </IconButton>

      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <div className="w-64 bg-primaryBlue h-full text-white">
          <div className="flex justify-between items-center p-4">
            <IconButton onClick={toggleDrawer(false)} className="text-white">
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          </div>
          <Divider sx={{ backgroundColor: 'white', margin: 2 }} />
          <div className="flex justify-center items-center p-4">
            <Logo type="col" size={150} />
          </div>
          <List>
            <RenderLink to="/home" text="Etusivu" icon={<HomeIcon sx={{ color: 'white' }} />} />

            <RenderLink to="/about" text="Tietoa meistä" icon={<InfoIcon sx={{ color: 'white' }} />} />

            <RenderLink to="/properties" text="Ominaisuudet" icon={<InfoIcon sx={{ color: 'white' }} />} />

            <RenderLink to="/contact" text="Ota yhteyttä" icon={<ContactPageIcon sx={{ color: 'white' }} />} />

            {/* Render dashboard links based on user role */}
            {user &&
              !isReseller &&
              dashboardLinks.map((link) => (
                <ListItemButton
                  key={link.to}
                  onClick={() => {
                    navigate(link.to);
                    setOpen(false);
                  }}
                >
                  <ListItemIcon className="text-white">{link.icon}</ListItemIcon>
                  <ListItemText primary={link.text} />
                </ListItemButton>
              ))}

            <Divider sx={{ backgroundColor: 'white', margin: 2 }} />

            {/* Login/Logout links */}
            {user ? (
              <ListItem button onClick={handleLogout}>
                <LogoutIcon sx={{ margin: 2 }} />

                <ListItemText primary="Kirjaudu ulos" />
              </ListItem>
            ) : (
              <>
                <ListItemButton
                  onClick={() => {
                    navigate('/login');
                    setOpen(false);
                  }}
                >
                  <LoginIcon sx={{ margin: 2 }} />
                  <ListItemText primary="Kirjaudu sisään" />
                </ListItemButton>
              </>
            )}
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default MobileNavBar;
