import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TeltonikaSetupGuide from '../guides/configureRUT';
import Modal from '../../components/modal/modal';
import { NoSites } from './components/noSites';
import { SiteRow } from './components/siteRow';
export type MediaSize = {
  mobile: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
};

export type Site = {
  name: string;
  address: string;
  contactPerson: string;
  phone: string;
  cameras: any;
  users: any;
  x09: string;
  x08: string;
  m09: string;
  m08: string | null;
  rutAddress: any;
  subscription: any;
  targetID: any;
  siteID: any;
  gdprUrl: any;
};

export default function CollapsibleTable({
  userSites,
  setHandleUpdate,
  handleUpdate,
  errorCameras,
  mediaSize,
}: {
  userSites: any[] | null;
  setHandleUpdate: any;
  handleUpdate: number;
  errorCameras: string[];
  mediaSize: MediaSize;
}) {
  const [rutGuide, setRutGuide] = React.useState(false);
  const [rut, setRut] = React.useState(false);

  if (!userSites) {
    return <div>Loading...</div>;
  }
  if (!userSites.length) {
    return <NoSites />;
  }

  const rows = userSites.map((site) => ({
    name: site.basicInfo.name,
    address: `${site.basicInfo.street}, ${site.basicInfo.city}, ${site.basicInfo.postalCode}`,
    contactPerson: site.contactPerson.name,
    phone: site.contactPerson.phone,
    cameras: site.cameras,
    users: site.remoteUsers,
    x09: site.x09,
    x08: site.x08,
    m09: site.m09,
    m08: site.m08,
    rutAddress: site.rutAddress,
    subscription: site.subscription,
    targetID: site.targetID,
    siteID: site.id,
    gdprUrl: site.GDPR,
  }));

  const handeRutGuide = (rut: any) => {
    setRut(rut);
    setRutGuide(!rutGuide);
  };

  const sx = {
    fontSize: { xs: '0.8rem', sm: '0.8rem', md: '1rem ' },
    color: 'white',
    fontFamily: 'poppins',
  };

  return (
    <>
      <Modal show={rutGuide} onClose={() => setRutGuide(false)}>
        <TeltonikaSetupGuide rut={rut} />
      </Modal>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow
              sx={{
                '& > *': {
                  borderBottom: 'unset',
                  backgroundColor: '#43687e',
                  padding: { xs: '0.3rem', sm: '0.5rem', md: '1rem ' },
                },
              }}
            >
              <TableCell />
              <TableCell sx={sx} align="left">
                Kohde
              </TableCell>
              {(mediaSize.md || mediaSize.lg || mediaSize.xl) && (
                <TableCell sx={sx} align="center">
                  Osoite
                </TableCell>
              )}
              <TableCell sx={sx} align="center">
                Yhteyshenkilö
              </TableCell>
              <TableCell sx={sx} align="center">
                Puhelin
              </TableCell>
              <TableCell sx={sx} align="center">
                Lataukset
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="bg-stone-200">
            {rows.map((row, index) => (
              <SiteRow key={index} site={row} mediaSize={mediaSize} setHandleUpdate={setHandleUpdate} handleUpdate={handleUpdate} handeRutGuide={handeRutGuide} errorCameras={errorCameras} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
