import { Typography } from '@mui/material';
import camImage from '../../assets/pictures/OBS-tietokone.png';

export const SecondIntroduction = () => {
  return (
    <div className="flex flex-wrap justify-center items-center  bg-white">
      <div className="lg:w-4/5 justify-center items-center flex flex-wrap text-center">
        <div className="flex justify-end md:w-1/3 p-10">
          <img
            src={camImage}
            alt="Kamera"
            className="w-full h-auto min-w-[56px] min-h-14 md:min-w-[112px] max-w-[350px] justify-center items-center p-4 md:p-11"
          />
        </div>

        <div className="w-full md:w-2/3 h-full flex items-center justify-center ">
          <Typography
            sx={{
              fontFamily: 'open-sans',
              fontSize: '1rem',
              color: '#666666',
              lineHeight: '1.5',
              fontWeight: 600,
              padding: { xs: '4% 10%', md: '0%' },
            }}
            gutterBottom
            component="div"
            className="w-full md:w-4/5"
          >
            OBSCloud on innovatiivinen kameravalvonnan ratkaisu, joka
            mahdollistaa helpon tavan hallinnoida kameroitasi. Kevyt,
            helppokäyttöinen ja suomalainen palvelumme takaa turvallisen ja
            luotettavan valvonnan missä ja milloin tahansa. Tutustu OBSCloudiin
            ja koe uusi tapa valvoa omaisuuttasi!
          </Typography>
        </div>
      </div>
    </div>
  );
};
