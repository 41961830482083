import React from 'react';
import { Typography } from '@mui/material';
import dahualogo from '../../assets/logos/dahua.svg';
import teltonikaLogo from '../../assets/logos/teltonika.svg';
import obseron from '../../assets/logos/obseron_icon.webp';

export const NeedToStart = () => {
  return (
    <div className="w-full justify-center text-center items-center flex">
      <div className="flex flex-wrap  items-center md:w-4/5 justify-center">
        <div className="w-full md:w-1/2 p-4">
          <div>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '1.75rem',
                fontWeight: 600,
              }}
              gutterBottom
              component="div"
              color="white"
            >
              Mitä tarvitset aloittaaksesi?
            </Typography>
            <Typography
              sx={{
                fontFamily: 'open-sans',
                fontWeight: 600,
                fontSize: '1rem',
              }}
              gutterBottom
              component="div"
              color="white"
            >
              OBSCloudin käyttöönotto on helppoa ja nopeaa. Tarvitset vain
              reitittimen, kameran ja internet-yhteyden.
            </Typography>
          </div>
        </div>
        <div className="w-full md:w-1/2 flex flex-wrap justify-evenly p-4">
          <div className="flex flex-col items-center gap-4 p-4">
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: '1.4rem',
              }}
              gutterBottom
              component="div"
              color="white"
            >
              Yhteensopivat laitteet
            </Typography>
            <div className="flex flex-wrap gap-10 justify-center">
              <div className="flex flex-col gap-4 items-center justify-between">
                <img
                  src={dahualogo}
                  alt="Dahua"
                  width={120}
                  className="hover:scale-125 ease-in-out duration-300 rounded-xl "
                  /* style={{ boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)' }} */
                />
                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                  }}
                  color="white"
                  gutterBottom
                  component="div"
                >
                  Kamerat
                </Typography>
              </div>
              <div className="flex flex-col gap-4 items-center justify-between ">
                <img
                  src={teltonikaLogo}
                  alt="Teltonika"
                  width={160}
                  className="hover:scale-125 ease-in-out duration-300 rounded-xl "
                />
                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                  }}
                  color="white"
                  gutterBottom
                  component="div"
                >
                  Reitittimet
                </Typography>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3 items-center justify-center text-center ">
            <img
              src={obseron}
              alt="Obseron"
              style={{
                height: '50%',
                width: '50%',
                maxWidth: '200px',
                maxHeight: '200px',
                boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
              }}
              className="rounded-xl hover:scale-110 ease-in-out duration-300"
            />

            <Typography
              sx={{
                fontSize: '1rem',
                fontFamily: 'Poppins',
                fontWeight: 600,
                width: '70%',
              }}
              color="white"
              gutterBottom
              component="div"
            >
              Obseron etäkäyttösovellus
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
