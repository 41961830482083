import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
const RegisterNow = ({ text }: { text: string }) => {
  return null;
  /* return (
    <div className=" flex hover:scale-110 ease-in-out duration-300 ">
      <Link to="/register">
        <Button
          sx={{
            minWidth: '200px',
            padding: '10px 20px',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontFamily: 'poppins',
            borderRadius: 30,
            fontWeight: 600,

            color: 'white',
            backgroundColor: '#43687e',
            '&:hover': {
              backgroundColor: '#2c4f5a',
            },
          }}
        >
          {text}
        </Button>
      </Link>
    </div>
  ); */
};

export default RegisterNow;
