import { Divider, Typography } from '@mui/material';
import image1 from '../../../assets/guides/obsmobile1.jpeg';
import image2 from '../../../assets/guides/obsmobile2.jpeg';
import image3 from '../../../assets/guides/obsmobile3.jpeg';
import google from '../../../components/svg/google-play.svg';
import apple from '../../../components/svg/appstore.svg';
import RenderImage from './renderImage';
import { useState } from 'react';
const DownloadMobileApp = () => {
  return (
    <div className="flex flex-col items-center mt-10 ">
      <Typography variant="h6" className="text-center text-blue-gray-700">
        Lataa Obseron puhelimellesi sovelluskaupasta
      </Typography>
      <div className="flex gap-4 pt-4 justify-evenly w-full">
        <a href="https://play.google.com/store/apps/details?id=com.valvova.obseron3" target="_blank" rel="noopener noreferrer">
          <img src={google} alt="Google Play" className="w-28 hover:scale-110 transition-transform duration-300" />
        </a>
        <a href="https://apps.apple.com/app/id1533044338" target="_blank" rel="noopener noreferrer">
          <img src={apple} alt="App Store" className="w-28 hover:scale-110 transition-transform duration-300" />
        </a>
      </div>
    </div>
  );
};

const imageProps = [
  {
    src: image1,
    alt: 'Kirjautumisnäkymä',
    text: '1. Valitse Yhteydet',
    className: 'w-1/3 rounded-md shadow-md',
    helperText: 'Avaa Yhteydet välilehti',
    imageAnimationProps: { top: '90%', left: '6%', width: '50px', height: '50px' },
  },
  {
    src: image2,
    alt: 'Kirjautumisnäkymä',
    text: '2. Lisää yhteys',
    className: 'w-1/3 rounded-md shadow-md',
    helperText: 'Valitse + ',
    imageAnimationProps: { top: '4%', left: '88%', width: '50px', height: '50px' },
  },
  {
    src: image3,
    alt: 'Kirjautumisnäkymä',
    text: '3. Lisää käyttäjätietosi',
    className: 'w-1/3 rounded-md shadow-md',
    credentials: true,
  },

  {
    src: image2,
    alt: 'Kirjautumisnäkymä',
    text: '4. Avaa yhteys',
    className: 'rounded-md shadow-md',
    imageAnimationProps: { top: '9%', left: '86%', width: '50px', height: '50px' },
    helperText: 'Avaa ObsCloud yhteys',
  },
  {
    src: image2,
    alt: 'Kirjautumisnäkymä',
    text: '5. Avaa kamerat',
    className: 'rounded-md shadow-md',
    imageAnimationProps: { top: '90%', left: '31%', width: '50px', height: '50px' },
    helperText: 'Kun yhteys on avattu, voit aloittaa kameroiden katselun tästä',
  },
];

const Mobiili = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  return (
    <div className="p-8 flex flex-col items-center bg-gray-50 rounded-lg shadow-lg">
      <Typography variant="h5" className="text-center font-bold text-blue-gray-800 italic pb-6">
        Obseron mobiilisovelluksen käyttöönotto
      </Typography>
      <Typography variant="body1" className="text-center mb-6 px-12 leading-relaxed">
        Saitko sähköpostiisi kutsun ObsCloud-pilvipalveluun? Seuraa alla olevia ohjeita ottaaksesi Obseron mobiilisovelluksen käyttöön.
      </Typography>
      <Divider className="w-4/5 p-6" />
      <DownloadMobileApp />

      <Typography variant="h6" className="text-center pt-8 pb-4 text-blue-gray-700 font-medium">
        Aseta sovellukseen käyttäjätietosi seuraavasti
      </Typography>
      <div className="flex flex-row gap-2 w-full justify-evenly items-start m-2">
        {imageProps.slice(0, 3).map((image, index) => (
          <RenderImage key={index} {...image} initialIndex={index} imageProps={imageProps} currentImage={{ currentImageIndex, setCurrentImageIndex }} />
        ))}
      </div>

      <Typography variant="h6" className="text-center pt-8 px-8 text-blue-gray-700 font-medium">
        Kun olet lisännyt käyttäjätietosi, voit aloittaa käytön yhdistämällä laitteesi ObsCloud-pilvipalveluun
      </Typography>
      <div className="w-full justify-evenly flex flex-row mt-6">
        {imageProps.slice(3).map((image, index) => (
          <RenderImage key={index} {...image} initialIndex={index + 3} currentImage={{ currentImageIndex, setCurrentImageIndex }} imageProps={imageProps} />
        ))}
      </div>
    </div>
  );
};

export default Mobiili;
