import React, { createContext, useContext, ReactNode } from 'react';
import { ToastContainer, toast, ToastOptions, ToastContent } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Contextin rajapinta, joka sisältää showToast-funktion
interface ToastContextProps {
  showToast: (message: ToastContent, options?: ToastOptions) => void;
}
interface ToastProviderProps {
  children: ReactNode;
}
// Luodaan ToastContext oletusarvoilla
export const ToastContext = createContext<ToastContextProps>({
  showToast: (message: ToastContent, options?: ToastOptions) => toast(message, options),
});

// Päivitetty ToastProvider, joka tarjoaa showToast-funktion, jolla voidaan muokata toasteja
export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  /**
   *  Funktio, joka näyttää toasteja käyttäjälle
   *
   * @param {message} ToastContent - viesti, joka näytetään toasteissa
   * @param {options} ToastOptions - asetukset, joilla voidaan muokata toasteja
   */
  const showToast = (message: ToastContent, options?: ToastOptions) => {
    toast(message, {
      position: options?.position || toast.POSITION.BOTTOM_CENTER, // Oletuksena toast näkyy alhaalla keskellä
      autoClose: options?.autoClose || 4000, // Oletuksena sulkeutuu 4 sekunnin jälkeen
      hideProgressBar: options?.hideProgressBar || true,
      closeOnClick: options?.closeOnClick || true,
      pauseOnHover: options?.pauseOnHover || false,
      draggable: options?.draggable || true,
      type: options?.type || 'default', // Oletuksena 'default', voi olla myös 'success', 'error', jne.
      ...options, // Muita mahdollisia asetuksia voidaan ohittaa options-parametrilla
    });
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      <ToastContainer />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;

/**
 *
 * @returns {ToastContextProps} - Palauttaa showToast-funktion, jolla voidaan näyttää toasteja
 */
export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
