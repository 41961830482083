import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
export const NoSites = () => {
  return (
    <div className="flex justify-center items-center flex-col gap-2 bg-greyBackground p-10 rounded-xl shadow-2xl">
      <Typography
        className="text-center mb-4"
        sx={{
          fontSize: '1.5rem',
          fontWeight: 600,
          color: '#333333',
          fontFamily: 'Poppins',
          lineHeight: '1rem',
        }}
      >
        Et ole vielä luonut etäkohteita
      </Typography>
      <br />
      <Typography
        variant="body1"
        className="text-center text-gray-600 mb-8 p-6"
        sx={{
          width: '80%',
          fontFamily: 'open-sans',
          fontSize: '1rem',
          fontWeight: 400,
        }}
      >
        Voit lisätä etäkohteita painamalla alla olevaa painiketta ja täyttämällä
        lomakkeen
      </Typography>
      <Link
        to="/addNewCustomer"
        className="bg-primary text-white font-bold py-2 px-4 rounded hover:bg-primary hover:scale-110 ease-in-out duration-300"
      >
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '1rem',
            fontWeight: 600,
            color: '#ffffff',
            lineHeight: '1rem',
            padding: '5px',
          }}
        >
          LISÄÄ SIVUSTO
        </Typography>
      </Link>
    </div>
  );
};
