import { Typography } from '@mui/material';
import { Features } from './features';

export const Properties = () => {
  return (
    <div className="flex flex-col w-full justify-center items-center gap-20 py-10 px-4 md:px-10  bg-greyBackground">
      <div className="lg:w-4/5 justify-center items-center gap-20 py-10 px-4 md:px-10 flex flex-col ">
        <div className="justify-center items-center flex flex-col gap-2 w-5/6  ">
          <Typography
            sx={{
              fontFamily: 'open-sans',
              fontSize: '1.7rem',
              color: '#333333',
              fontWeight: 500,
            }}
            gutterBottom
            component="div"
          >
            Ominaisuudet
          </Typography>
          <Typography
            variant="caption"
            className="text-almostBlack"
            sx={{
              fontFamily: 'open-sans',
              fontSize: '1rem',
              color: '#666666',
              padding: '0 10%',

              textAlign: 'center',
              fontWeight: 600,
            }}
            gutterBottom
            component="div"
          >
            OBSCloud on helppokäyttöinen, turvallinen ja luotettava kameroiden
            hallintapalvelu. Se tarjoaa kaikki tarvittavat ominaisuudet
            kameravalvonnan hallintaan.
          </Typography>
        </div>
        <div className="w-full justify-center flex-col grid grid-rows-2  gap-8 md:grid-cols-2 lg:grid-cols-3  ">
          <Features />
        </div>
      </div>
    </div>
  );
};
