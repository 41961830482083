import { Typography } from '@mui/material';
import RegisterNow from '../../components/button/registerNow';

export const Introduction = ({ currentUser }: { currentUser: any }) => {
  return (
    <div className="flex flex-wrap p-10 ">
      <div className="w-full   flex-col flex  text-white  items-start justify-center   ">
        <div className="lg:w-4/5 justify-center flex">
          <div className="w-5/6 md:w-4/5  mx-10  gap-2 sm:gap-3  flex flex-col lg:w-2/4">
            <Typography
              sx={{
                fontFamily: 'open-sans',
                fontWeight: 600,
                fontSize: '0.875rem', // Pienennetty fontin kokoa mobiilinäkymässä
              }}
              gutterBottom
              component="div"
              color="white"
            >
              OBSCLOUD - SE HELPOMPI KAMEROIDEN HALLINTA
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontFamily: 'Poppins',
                fontSize: '1.5rem',
              }}
              gutterBottom
              component="div"
              color="white"
            >
              Hallitse kameroitasi helposti reaaliajassa
            </Typography>
            <Typography
              sx={{
                fontFamily: 'open-sans',
                fontWeight: 600,
                lineHeight: '1.2',
                letterSpacing: '0.3px',

                fontSize: '1rem',
              }}
              gutterBottom
              component="div"
              color="white"
            >
              OBSCloudin avulla voit hallita kameroitasi helposti ja
              reaaliajassa.Voit hallita käyttäjiä ja kameroitasi mistä milloin
              tahansa Kevyt, helppokäyttöinen ja suomalainen palvelu tarjoaa
              turvallisen ja luotettavan kameravalvonnan.
            </Typography>
            <div className="flex w-full justify-start md:pl-8 items-center md:mt-8 ">
              {!currentUser && (
                <RegisterNow text="ALOITA NYT REKISTERÖITYMÄLLÄ" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
