import React, { useState } from 'react';
import { useApi } from '../../../context/ApiCalls';
import { Input } from '@material-tailwind/react';

const AddNewCamera = ({ site, closeModal, handleUpdate, setHandleUpdate }: any) => {
  const [cameraName, setCameraName] = useState<string | null>(null);
  const [cameraLocation, setCameraLocation] = useState('');
  const { addCamera } = useApi();
  const handleNewCamera = async () => {
    if (!cameraName) {
      return;
    }
    const camera = { name: cameraName, location: cameraLocation };
    const response = await addCamera({
      camera: camera,
      siteID: site.siteID,
    });

    if (response) {
      setHandleUpdate(handleUpdate + 1);
    }

    closeModal();
  };

  const sx = {
    fontFamily: 'open-sans',
    fontSize: '1rem',
    fontWeight: 600,
    color: '#5c5c5c',
    maxWidth: 270,
  };

  return (
    <div className="flex flex-col gap-10 p-5 sm:p-10 justify-center items-center h-full max-w-[720px]">
      <div className="flex flex-col sm:flex-row gap-5 justify-center items-center w-full sm:w-auto">
        <Input type="text" crossOrigin={true} onChange={(e) => setCameraName(e.target.value)} label="Kameran nimi" color="light-blue" style={sx} variant="standard" required />

        <Input label='Kameran sijainti (esim. "SK201")' type="text" crossOrigin={true} style={sx} color="light-blue" variant="standard" onChange={(e) => setCameraLocation(e.target.value)} />
      </div>
      <div className="flex gap-5 w-full sm:w-auto justify-center items-center">
        <p className="text-xs sm:text-sm text-center">
          Kameran lisäys päivittää laskutuksen hinnaston mukaisesti. Varmista, että lisättävät tiedot ovat oikein. Tähdellä merkityt kentät ovat pakollisia.
        </p>
      </div>
      <button
        style={{
          fontFamily: 'poppins',
          fontWeight: 600,
          color: 'white',
        }}
        className="bg-blueBackground text-sm w-4/5 sm:w-3/5 py-2 rounded-3xl hover:bg-primary hover:scale-110 ease-in-out duration-300"
        onClick={handleNewCamera}
      >
        LISÄÄ KAMERA
      </button>
    </div>
  );
};

export default AddNewCamera;
