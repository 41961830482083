import { Link, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';

export const DashboardLink = ({ to, text, icon }: any) => {
  const location = useLocation();
  const currentLink = location.pathname;

  const isActive = currentLink === to ? 'text-activeTab' : '';
  return (
    <Link
      to={to}
      className={`${isActive} flex px-4 py-2  text-xs md:text-xs lg:text-base rounded-b-xl flex-row items-center gap-2 hover:scale-110 ease-in-out duration-300`}
    >
      <Typography
        sx={{
          fontSize: '1rem',
          fontWeight: 500,
          lineHeight: '1rem',
          fontFamily: 'Poppins',
          padding: '5px',
        }}
      >
        {text.toUpperCase()}
      </Typography>
    </Link>
  );
};

export const handleDashboardClick = (
  { to, text, icon }: any,
  index: any,
  setShowMenu: any
) => {
  return (
    <Link
      key={index}
      to={to}
      className="flex px-4 py-2  rounded-b-xl  flex-row items-center gap-2 hover:scale-110 ease-in-out duration-300"
      onClick={() => setShowMenu(false)}
    >
      {icon}
      <Typography
        sx={{
          fontSize: '1rem',
          fontWeight: 500,

          lineHeight: '1rem',
          fontFamily: 'Poppins',
          padding: '5px',
        }}
      >
        {text}
      </Typography>
    </Link>
  );
};
