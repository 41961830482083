import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import Routers from './tabs/routers';
import { Typography } from '@material-tailwind/react';

const SupportedDevices = () => {
  const [value, setValue] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [animationDirection, setAnimationDirection] = useState('');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue !== value) {
      // Määritellään animaation suunta
      if (newValue > value) {
        setAnimationDirection('left'); // Uusi arvo on suurempi
      } else {
        setAnimationDirection('right'); // Uusi arvo on pienempi
      }
      setIsAnimating(true); // Käynnistetään animaatio
      setTimeout(() => {
        setValue(newValue); // Päivitetään uusi arvo
        setIsAnimating(false); // Lopetetaan animaatio
      }, 500); // Animaation kesto
    }
  };

  const RenderContent = () => {
    switch (value) {
      case 0:
        return <Routers />;
      case 1:
        return (
          <div className="h-full w-full min-h-[70vh] items-center flex justify-center bg-white rounded-2xl ">
            <Typography placeholder={''} style={{ fontSize: '1.25rem', fontFamily: 'poppins', fontWeight: 500 }} className="text-center">
              Tulossa pian!
            </Typography>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full h-[85vh] lg:h-[80vh] flex flex-col">
      <Tabs
        value={value}
        onChange={handleChange}
        centered
        className="bg-transparent text-white rounded-t-xl m-4 drop-shadow-2xl"
        textColor="inherit"
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: 'white',
          },
        }}
      >
        <Tab label="Reitittimet" sx={{ color: value !== 0 ? '#52525b' : 'white', fontFamily: 'poppins' }} />
        <Tab label="Kamerat" sx={{ color: value !== 1 ? '#52525b' : 'white', fontFamily: 'poppins' }} />
      </Tabs>
      <div className={`relative overflow scrollbar-hide flex-1`} style={{ position: 'relative' }}>
        <div
          className={` inset-0 flex items-center justify-center transition-all duration-500 ease-in-out`}
          style={{
            opacity: isAnimating ? 0 : 1,
            transform: isAnimating ? (animationDirection === 'left' ? 'translateX(-100%)' : 'translateX(100%)') : 'translateX(0)',
          }}
        >
          <RenderContent />
        </div>
      </div>
    </div>
  );
};

export default SupportedDevices;
