import React, { useEffect, useRef } from 'react';
import { Logo } from './logo';
const clouds = [
  { x: 13, y: 8, r: 3 },
  { x: 8, y: 14, r: 4 },

  { x: 14, y: 14, r: 4 },
  { x: 14, y: 14, r: 4 },
  { x: 14, y: 20, r: 4 },
  { x: 20, y: 8, r: 4 },
  { x: 20, y: 14, r: 4 },
  { x: 20, y: 20, r: 3 },
  { x: 26, y: 8, r: 4 },
  { x: 26, y: 14, r: 4 },
  { x: 26, y: 20, r: 4 },
  { x: 23, y: 17, r: 4 },
  { x: 23, y: 12, r: 4 },
  { x: 17, y: 12, r: 4 },
  { x: 17, y: 17, r: 4 },
  { x: 32, y: 14, r: 4 },
  { x: 32, y: 20, r: 4 },
];

const Cloud = React.memo(({ position }: { position?: DOMRect | null }) => {
  // Ref to the SVG element
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const svgElement = svgRef.current;
    if (!svgElement) return;

    // Function to update cloud positions
    const updateOffsets = () => {
      const cloudElements = svgElement.querySelectorAll<SVGCircleElement>('circle');

      cloudElements.forEach((circle, index) => {
        const randomX = Math.random() * 1.3 - 0.85;
        const randomY = Math.random() * 1.3 - 0.85;
        circle.setAttribute('cx', (clouds[index].x + randomX).toString());
        circle.setAttribute('cy', (clouds[index].y + randomY).toString());
      });

      setTimeout(() => {
        requestAnimationFrame(updateOffsets);
      }, 2000);
    };

    // Start the animation
    updateOffsets();
  }, []);

  return (
    <div
      style={{
        zIndex: 10,
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '50%',
          top: '50%',
          left: '50%',
          transform: 'translate(-70%, -75%)',
          zIndex: 10,
          fill: 'black',
        }}
      >
        <Logo />
      </div>

      <svg
        ref={svgRef}
        id="main"
        viewBox="0 0 50 30"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          transition: 'transform 2s ease-in-out',
          backgroundColor: 'transparent',
          padding: '1rem',
          borderRadius: '1rem',
          zIndex: 9,
        }}
      >
        <defs>
          <linearGradient id="cloudGradient" x1="0" y1="1" x2="0" y2="0">
            <stop offset="0%" stopColor="skyblue" />
            <stop offset="100%" stopColor="lightgray" />
          </linearGradient>
          <filter id="cloudOutline">
            <feMorphology in="SourceAlpha" operator="dilate" radius="0.2" result="dilated" />
            <feFlood floodColor="url(#cloudGradient)" result="flood" />
            <feComposite in="flood" in2="dilated" operator="in" result="outline" />
            <feComposite in="SourceGraphic" in2="outline" operator="over" />
          </filter>
        </defs>
        <g filter="url(#cloudOutline)">
          {clouds.map((cloud, index) => (
            <circle
              key={index}
              cx={cloud.x}
              cy={cloud.y}
              r={cloud.r}
              id={`cloud${index}`}
              strokeWidth={2}
              stroke="skyblue"
              fill="skyblue"
              style={{
                transition: 'all 2s ease-in-out',
              }}
            />
          ))}
        </g>
      </svg>
    </div>
  );
});

export default Cloud;
