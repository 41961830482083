import { useState } from 'react';

const UpdateLicense = ({
  licenseUpdateServer,
  updateLicenseApi,
}: {
  licenseUpdateServer: any;
  updateLicenseApi: (
    serverId: number,
    newLicenseCount: number
  ) => Promise<void>; // API call function
}) => {
  // State to manage license count input
  const [licenseCount, setLicenseCount] = useState(
    licenseUpdateServer?.licenseCount || 0
  );
  const [inputValue, setInputValue] = useState(
    licenseUpdateServer?.licenseCount || ''
  );

  // Handle input change and ensure it doesn't go over 256
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value <= 256) {
      setInputValue(e.target.value);
    } else if (isNaN(value)) {
      setInputValue('');
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    const newLicenseCount = parseInt(inputValue, 10);
    if (
      !isNaN(newLicenseCount) &&
      newLicenseCount <= 256 &&
      newLicenseCount >= 0
    ) {
      try {
        await updateLicenseApi(licenseUpdateServer.id, newLicenseCount);
        setLicenseCount(newLicenseCount); // Update the local state if the API call succeeds
      } catch (error) {
        console.error('Failed to update license', error);
        alert('Failed to update license.');
      }
    } else {
      alert('Please enter a valid number between 0 and 256.');
    }
  };

  const inputStyle = ' p-2 w-2/5 flex items-center justify-between';

  return (
    <div className="  flex items-center justify-center w-full ">
      <div className="bg-white p-6 rounded-lg shadow-lg justify-center items-center flex flex-col  gap-6 w-full">
        <p className={inputStyle}>
          Päivitä lisenssi palvelimelle:{' '}
          <span className="font-semibold">{licenseUpdateServer?.name}</span>
        </p>
        <p className={inputStyle}>
          Nykyinen lisenssien määrä:{' '}
          <span className="font-semibold">{licenseCount}</span>
        </p>
        <p className={inputStyle}>
          IP-Osoite:{' '}
          <span className="font-semibold">{licenseUpdateServer?.ip}</span>
        </p>
        <p className={inputStyle}>
          Portti:{' '}
          <span className="font-semibold">{licenseUpdateServer?.port}</span>
        </p>
        <p className={inputStyle}>
          Host:{' '}
          <span className="font-semibold">{licenseUpdateServer?.host}</span>
        </p>

        <div className="mb-4 w-full justify-center items-center flex gap-6">
          <label
            htmlFor="licenseCount"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Uusi lisenssien määrä:
          </label>
          <input
            id="licenseCount"
            type="number"
            value={inputValue}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md p-2 w-1/5"
            min="0"
            max="256"
          />
        </div>

        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 w-[200px] text-center"
          onClick={handleSubmit}
        >
          Päivitä lisenssit
        </button>
      </div>
    </div>
  );
};

export default UpdateLicense;
