import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/auth/login';
import Register from './components/auth/register';
import Dashboard from './pages/dashboard/dashBoard';
import AddNewCustomer from './pages/cameraManagement/addNewCustomer';
import Subscriptions from './pages/subscriptions/subscriptions';
import MyAccount from './pages/cameraManagement/myAccount/myAccount';
import PrivacyPolicy from './pages/privacyPolicy/privacyPolicy';
import Contact from './pages/contact/contact';
import ResellerMain from './pages/resellerManagement/resellerMain';
import Layout from './Layout';
import RequireAuth from './components/requireAuth';
import AdminPage from './pages/admin/adminPage';
import Home from './pages/home/home';
import About from './pages/about/about';
import EmailVerifiedPage from './pages/verify/emailVerified';
import PropertiesMain from './pages/properties/propertiesMain';
const Guide = () => <h1>Guide</h1>;
function App() {
  const roles = {
    user: 'USER',
    reseller: 'RESELLER',
    admin: 'ADMIN',
    billingCustomer: 'BILLING_CUSTOMER',
  };
  const status = true;
  if (!status) {
    return (
      <div className="flex items-center justify-center h-full text-white bg-gray-800 flex-col gap-4">
        <h1>opening soon</h1>
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route path="/" element={<Layout />}>
        {/* PUBLIC ROUTES */}

        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/properties" element={<PropertiesMain />} />
        <Route path="/unauthorized" element={<h1>Unauthorized</h1>} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        {/* USER ROUTES */}
        <Route element={<RequireAuth allowedRoles={[roles.user, roles.admin, roles.reseller]} />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/myAccount" element={<MyAccount />} />
          <Route path="/addNewCustomer" element={<AddNewCustomer />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/myAccount" element={<MyAccount />} />
          <Route path="/guides" element={<Guide />} />
          <Route path="/verify" element={<EmailVerifiedPage />} />
        </Route>
        {/* RESELLER ROUTES */}
        <Route element={<RequireAuth allowedRoles={[roles.reseller]} />}>
          <Route path="/reseller" element={<ResellerMain />} />
        </Route>
        {/* ADMIN ROUTES */}
        <Route element={<RequireAuth allowedRoles={[roles.admin]} />}>
          <Route path="/admin" element={<AdminPage />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
const NotFound = () => {
  return (
    <div className="flex items-center justify-center h-full text-white bg-gray-800 flex-col gap-4">
      <h1>404 - Sivua ei löydy</h1>
      <p>
        Sivua ei löytynyt, tarkista osoite ja yritä uudelleen. Jos ongelma jatkuu, ota yhteyttä{' '}
        <a href="/contact" className="underline">
          tukeen
        </a>
      </p>
      <a href="/home" className="underline">
        Palaa etusivulle
      </a>
    </div>
  );
};
