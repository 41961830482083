import React from 'react';

const CameraConfiguration = ({ camera }: { camera: any }) => {
  const {
    camera: { password, ip: cameraIP, gateway },
  } = camera;

  return (
    <div className="p-6  rounded-lg overflow-auto max-h-[600px]">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Kameran Konfigurointiohjeet</h2>
      <ol className="list-decimal list-inside space-y-4 text-gray-700  justify-center items-center w-full">
        <li>Asenna kamera haluttuun paikkaan ja varmista, että se on tukevasti kiinnitetty.</li>
        <li>Kytke kamera virtalähteeseen tai liitä se PoE-verkkoon (Power over Ethernet), jos käytät PoE-yhteensopivaa reititintä.</li>
        <li>Kytke kamera samaan lähiverkkoon, jossa konfiguroitu reititin on. Varmista, että reititin ja kamera ovat yhteydessä toisiinsa joko verkkokaapelilla tai Wi-Fi-yhteydellä.</li>
        <li>
          Aseta tietokoneesi IP-osoite väliaikaisesti samalle IP-alueelle kuin kameran oletus-IP, esim. IP-alueelle <span className="font-semibold">192.168.1.0/24</span>. Esimerkiksi aseta
          tietokoneesi IP-osoitteeksi <span className="font-semibold">192.168.1.99</span> ja aliverkon peitteeksi <span className="font-semibold">255.255.255.0</span>. Muista palauttaa tietokoneen
          IP-osoite takaisin automaattiseen tilaan (DHCP) konfiguroinnin jälkeen.
        </li>
        <li>
          Avaa verkkoselain ja kirjoita osoiteriville kameran oletus-IP-osoite: <span className="font-semibold">http://192.168.1.108</span>. Paina Enter.
        </li>
        <li>Valitse Country/Region-asetukseksi haluamasi maa tai alue.</li>
        <li>Hyväksy käyttöehdot.</li>
        <li>Aseta kameran aika ja aikavyöhyke vastaamaan kameran sijaintia.</li>
        <li>
          Aseta kameran salasanaksi <span className="font-bold text-gray-900">{password}</span> ja määritä sähköpostiosoite, johon saat palautuslinkin, jos unohdat salasanan.
        </li>
        <li>Jätä P2P-asetukset oletusarvoiksi ja siirry seuraavaan vaiheeseen valitsemalla "Next".</li>
        <li>Jätä päivitysasetukset oletusarvoiksi ja tallenna muutokset valitsemalla "Save".</li>
        <li>
          Kirjaudu kameraan antamillasi tunnuksilla. Käyttäjätunnus on <span className="font-semibold">admin</span> ja salasana on <span className="font-semibold">{password}</span>.
        </li>
        <li>Siirry valikosta kohtaan "Settings" -&gt; "Network" -&gt; "TCP/IP".</li>
        <li>
          Aseta kameran IP-osoitteeksi <span className="font-bold">{cameraIP}</span>, aliverkon peitteeksi <span className="font-semibold">255.255.255.0</span> ja Gatewayksi{' '}
          <span className="font-bold">{gateway}</span>. Tallenna muutokset valitsemalla "Save".
        </li>
        <li>Voit nyt sulkea selaimen. Kamera on valmis käytettäväksi ja se tulee näkymään Obseron etäohjelmassa hetken kuluttua.</li>
      </ol>
    </div>
  );
};

export default CameraConfiguration;
