import React from 'react';
import { TextField, Button, Typography } from '@mui/material';
import { useAuth } from '../../context/authContext';
import { useToast } from '../../context/toastContext';
import CircularProgress from '@mui/material/CircularProgress';
import './style.css';

async function sendFeedback(feedback: any) {
  const apikey = process.env.REACT_APP_APIBACKENDKEY as string;
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/feedback`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apikey,
    },
    body: JSON.stringify(feedback),
  });
  return response;
}

const Contact = () => {
  const { currentUser } = useAuth();
  const { showToast } = useToast();
  const [Loading, setLoading] = React.useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const feedback = {
      name: e.target.name.value,
      email: e.target.email.value,
      feedback: e.target.feedback.value,
      user: currentUser?.uid || null,
    };
    const response = await sendFeedback(feedback);

    if (response.status === 200) {
      showToast('Kiitos palautteestasi!');
      // reset form
      e.target.reset();
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center h-full w-full  ">
      <div className="h-full mx-2  ">
        <form
          className="flex flex-col  gap-8 p-8 max-w-lg w-full text-almostBlack rounded-2xl bg-white"
          onSubmit={(e) => handleSubmit(e)}
          style={{ height: 'auto' }} // Varmista, että korkeutta ei rajoiteta
        >
          <Typography
            sx={{
              fontFamily: 'poppins',
              fontSize: '1.5rem',
              fontWeight: 600,
              color: 'almost-black',
            }}
          >
            OTA YHTEYTTÄ
          </Typography>
          <Typography
            sx={{
              fontFamily: 'open-sans',
              color: 'black',
              fontWeight: 500,
              letterSpacing: -0.3,
              lineHeight: 1.3,
              padding: '1rem',
            }}
          >
            Tervetuloa OBSCloud-palvelun yhteydenottosivulle. <br />
            <br />
            Olipa sinulla kysyttävää valvontakameroiden tallennuksesta, tarvitset teknistä tukea tai haluat antaa palautetta palvelustamme, olemme täällä auttaaksemme.
          </Typography>
          <TextField
            variant="standard"
            id="name"
            sx={{ fontFamily: 'open-sans', color: 'black' }}
            type="text"
            required
            className="input font-openSans"
            label="Nimi"
            InputLabelProps={{
              style: { color: '#005a8D', fontFamily: 'open-sans' },
            }}
          />
          <TextField
            variant="standard"
            id="email"
            sx={{ fontFamily: 'open-sans', color: 'black' }}
            type="email"
            InputLabelProps={{
              style: { color: '#005a8D', fontFamily: 'open-sans' },
            }}
            required
            label="Sähköposti"
          />
          <TextField
            sx={{ fontFamily: 'open-sans', color: 'black' }}
            id="feedback"
            variant="outlined"
            multiline
            InputLabelProps={{
              style: { color: '#005a8D', fontFamily: 'open-sans' },
            }}
            label="Anna palautetta"
            rows={4}
          />
          <div className="flex justify-center w-full hover:scale-110 ease-in-out duration-300">
            <Button
              type="submit"
              variant="contained"
              className="hover:scale-110 ease-in-out duration-300"
              sx={{
                fontFamily: 'poppins',
                borderRadius: 25,
                fontWeight: 600,
                letterSpacing: -0.3,
                color: 'almost-black',
                backgroundColor: '#43687e',
                '&:hover': {
                  backgroundColor: '#2c4f5a',
                },
              }}
            >
              {Loading ? <CircularProgress size={25} color="warning" /> : 'Lähetä palaute'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
