import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import AddHomeIcon from '@mui/icons-material/AddHome';
import CameraOutdoorIcon from '@mui/icons-material/CameraOutdoor';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupsIcon from '@mui/icons-material/Groups';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
type Roles = 'USER' | 'RESELLER' | 'ADMIN' | 'BILLINGCUSTOMER';
type Actions = {
  icon: JSX.Element;
  name: string;
  function?: string;
  link?: string;
  roles: Roles[];
};

const actions: Actions[] = [
  { icon: <LogoutIcon />, name: 'Kirjaudu ulos', function: 'logout', roles: ['USER', 'RESELLER', 'ADMIN', 'BILLINGCUSTOMER'] },
  { icon: <ManageAccountsIcon />, name: 'Profiili', link: '/myAccount', roles: ['USER', 'RESELLER', 'ADMIN', 'BILLINGCUSTOMER'] },
  { icon: <AddHomeIcon />, name: 'Uusi kohde', link: '/addNewCustomer', roles: ['USER', 'RESELLER', 'ADMIN', 'BILLINGCUSTOMER'] },
  { icon: <CameraOutdoorIcon />, name: 'Hallintapaneeli', link: '/dashboard', roles: ['USER', 'RESELLER', 'ADMIN', 'BILLINGCUSTOMER'] },
  { icon: <GroupsIcon />, name: 'Jälleenmyyjä hallinta', link: '/reseller', roles: ['RESELLER'] },
  { icon: <AdminPanelSettingsIcon />, name: 'Adminpaneeli', link: '/admin', roles: ['ADMIN'] },
];

type Position = { x: number; y: number };

export default function SpeedDialComponent() {
  const [open, setOpen] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const speedDialRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [guidePosition, setGuidePosition] = useState({ x: 0, y: 0 });
  const guideRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const { user, logout } = useAuth();
  useEffect(() => {
    const optedOut = localStorage.getItem('navGuideOptedOut');
    if (!optedOut) {
      setShowGuide(true);
    }
  }, []);
  useEffect(() => {
    if (speedDialRef.current && user) {
      const { x, y } = speedDialRef.current.getBoundingClientRect();
      speedDialRef.current.getClientRects();

      let position = { x: parseInt(x.toFixed()), y: parseInt(y.toFixed()) };

      setPosition(position);
    }
  }, [speedDialRef, user]);
  useEffect(() => {
    if (guideRef.current && user) {
      const { x, y } = guideRef.current.getBoundingClientRect();
      guideRef.current.getClientRects();

      let position = { x: parseInt(x.toFixed()), y: parseInt(y.toFixed()) };

      setGuidePosition(position);
    }
  }, [guideRef, user]);
  if (!user) {
    return null;
  }
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClick = (action: any) => {
    if (action.function === 'logout') {
      handleClose();
      logout();
    }
    if (action.link) {
      handleClose();
      navigate(action.link);
    }
  };
  const isRoleAllowed = (action: Actions) => {
    if (action.roles) {
      return action.roles.includes(user.role);
    }
    return true;
  };

  const handleGuideOptOut = () => {
    localStorage.setItem('navGuideOptedOut', 'true');
    setShowGuide(false);
  };

  const DrawLineFromTo = ({ from, to }: { from: Position; to: Position }) => {
    // const { control1, control2 } = calculateControlPoints(from, to);
    const dx = to.x - from.x;
    const dy = to.y - from.y;

    // Asetetaan ohjauspisteet noin 30 % matkasta lähtöpisteestä päätepisteeseen,
    // ja siirretään niitä hieman ylös ja alas saadaksemme kaaren
    const control1 = { x: from.x + dx * 0.7, y: from.y - dy * 0.5 };
    const control2 = { x: from.x + dx * 0.3, y: from.y + dy * 0.5 };

    return (
      <svg width="100vw" height="100vh">
        <path d={`M${from.x},${from.y} C${control1.x},${control1.y} ${control2.x},${control2.y} ${to.x * 0.95},${to.y * 0.95}`} fill="none" stroke="white" strokeWidth="2" />
      </svg>
    );
  };

  return (
    <>
      {showGuide && (
        <div className=" fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <DrawLineFromTo from={guidePosition} to={position} />
        </div>
      )}
      {showGuide && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="relative bg-white p-6 rounded-lg shadow-lg text-center max-w-xs">
            <h4 className="text-xl font-semibold mb-2">Tervetuloa!</h4>
            <p className="text-sm mb-4">Tervetuloa käyttämään ObsCloud palvelua! Voit navigoida sivustolla käyttämällä pikavalikkoa, joka löytyy oikeasta alakulmasta.</p>

            <div ref={guideRef} className="flex justify-center gap-2 mt-2">
              <button className="text-sm text-gray-600 underline" onClick={handleGuideOptOut}>
                Älä näytä tätä uudelleen
              </button>
              <button className="bg-primaryBlue text-white px-4 py-2 rounded-lg" onClick={() => setShowGuide(false)}>
                Sulje
              </button>
            </div>
          </div>

          <div
            style={{
              top: position.y * 0.95,
              left: position.x * 0.95,
              transform: 'rotate(45deg)',
            }}
            className={`absolute`}
          >
            <ArrowForwardIcon sx={{ fontSize: 50, color: 'white' }} />
          </div>
        </div>
      )}
      <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1, position: 'relative', top: 0, left: 0, flex: 1 }}>
        <Backdrop open={open} />
        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          sx={{ position: 'fixed', bottom: 56, right: '15%' }}
          icon={<SpeedDialIcon ref={speedDialRef} openIcon={<AddHomeIcon />} />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map(
            (action) =>
              isRoleAllowed(action) && (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  tooltipOpen
                  onClick={() => handleClick(action)}
                  sx={{
                    '.MuiSpeedDialAction-staticTooltipLabel': {
                      width: 'auto',
                      maxWidth: 'none',
                      padding: '0 8px',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                  }}
                />
              )
          )}
        </SpeedDial>
      </Box>
    </>
  );
}
