import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid'; // Importtaa uuid-kirjaston v4-funktio
import { useAuth } from '../../../context/authContext';
import { useToast } from '../../../context/toastContext';
import { Input } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { Email } from '@mui/icons-material';
import { Button } from '@mui/material';
const InviteBilling = ({ invintationCustomer, handleResellerInvintation }: any) => {
  const { currentUser } = useAuth();
  const { showToast } = useToast();
  const [invitation, setInvitation] = useState({
    email: null,
    billingCustomerId: invintationCustomer?.id,
    token: uuidv4(),
    role: 'RESELLER',
  });

  const handleChange = (e: any) => {
    setInvitation({ ...invitation, email: e.target.value });
  };

  async function createInvitation() {
    if (invitation.email && invitation.billingCustomerId && currentUser) {
      const token = await currentUser.getIdToken();

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-invitation-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(invitation),
      });
      const data = await response.json();
      if (response.ok) {
        handleResellerInvintation(data);
        showToast('Kutsu lähetetty');
      } else {
        showToast(data.message);
      }
    } else {
      showToast('Täytä kaikki kentät');
    }
  }

  return (
    <div className="flex w-full h-full justify-center items-center flex-col ">
      <Input
        className="w-2/3 p-2 m-2 rounded-md border-2 border-blue-gray-300"
        type="text"
        placeholder="email"
        value={invitation.email || ''}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            <Email />
          </InputAdornment>
        }
      />
      <div className="p-2">
        <Button variant="outlined" size="large" onClick={createInvitation}>
          Lähetä kutsu
        </Button>
      </div>
    </div>
  );
};

export default InviteBilling;
