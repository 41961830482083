import React, { useState } from 'react';
import { Box, Typography, Button, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Modal from '../../../components/modal/modal';
import AddNewUser from './addNewUser';

const Remoteusers = ({ site, edit, handleDelete, handleUpdate, setHandleUpdate, mediaSize }: any) => {
  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => {
    setOpenModal(false);
  };
  const sx = {
    fontFamily: 'open-sans',
    fontSize: { xs: '0.8rem', sm: '0.8rem', md: '1rem ' },
    fontWeight: 600,
    textAlign: 'center',
    justifyContent: 'center',
  };
  const sxCell = {
    fontWeight: 500,
    fontFamily: 'open-sans',
    textAlign: 'center',
    fontSize: { xs: '0.8rem', sm: '0.8rem', md: '1rem ' },
    color: '#5c5c5c',
    justifyContent: 'center',
  };
  return (
    <>
      <Modal show={openModal} onClose={closeModal} title="Lisää etäkäyttäjä">
        <AddNewUser site={site} closeModal={closeModal} handleUpdate={handleUpdate} setHandleUpdate={setHandleUpdate} />
      </Modal>
      {/* Etäkäyttäjät */}
      <Box sx={{ margin: 4 }}>
        <Typography
          sx={{
            fontSize: { xs: '1.1rem', sm: '1.15rem', md: '1.25rem ' },
            fontWeight: 600,
            marginBottom: 2,
            fontFamily: 'open-sans',
          }}
          gutterBottom
          component="div"
        >
          Etäkäyttäjät ({site.users.length})
        </Typography>
        <Table size="small" aria-label="users">
          <TableHead>
            <TableRow>
              <TableCell sx={sx}>Nimi</TableCell>
              <TableCell sx={sx}>Sähköposti</TableCell>
              <TableCell sx={sx}>Puhelin</TableCell>

              {edit && (
                <TableCell sx={sx} align="center">
                  Muokkaa
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {site.users.map((user: any) => {
              return (
                <TableRow key={user.id}>
                  <TableCell sx={sxCell}>{user.name}</TableCell>
                  <TableCell sx={sxCell}>{user.email}</TableCell>
                  <TableCell sx={sxCell}>{user.phone}</TableCell>

                  {edit && (
                    <TableCell sx={sxCell} align="right">
                      <Tooltip title="Poista käyttäjä" placement="top">
                        <DeleteForeverIcon
                          sx={{ color: 'red', fontSize: 25 }}
                          className="cursor-pointer hover:scale-125 ease-in-out duration-300"
                          onClick={() => handleDelete({ id: user.id, type: 'user' })}
                        />
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {edit && (
          <div className="mt-4 w-full flex justify-end ">
            <div className="hover:scale-110 ease-in-out duration-300 ">
              <Button
                style={{
                  fontWeight: 600,
                  letterSpacing: -0.3,
                  color: 'white',
                  fontFamily: 'open-sans',
                }}
                className="  text-sm lg:text-sm font-bold py-2 px-4    rounded-2xl hover:shadow-xl"
                onClick={() => setOpenModal(true)}
                sx={{
                  fontFamily: 'poppins',
                  borderRadius: 25,
                  fontWeight: 600,
                  letterSpacing: -0.3,
                  paddingX: 3,
                  color: 'white',
                  backgroundColor: '#43687e',
                  '&:hover': {
                    backgroundColor: '#2c4f5a',
                  },
                }}
              >
                Lisää Etäkäyttäjä
              </Button>
            </div>
          </div>
        )}
      </Box>
    </>
  );
};

export default Remoteusers;
