import logo from './textLogo.svg';
import text from './text.svg';
import { useState, useEffect } from 'react';

const textDimensions = {
  height: 80,
  width: 620,
};
const iconDimensions = {
  height: 346,
  width: 286,
};
const logoDimensions = {
  text: textDimensions,
  logo: iconDimensions,
};

const Logo = ({ type, size, startAgain, setStartAgain }: { type: 'row' | 'col' | 'textonly'; size: number; startAgain?: boolean; setStartAgain?: any }) => {
  const textScale = size / logoDimensions.text.width;
  const logoScale = size / logoDimensions.logo.width / (type === 'col' ? 2 : 4);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (startAgain || !isLoaded) {
      setIsLoaded(false); // Piilotetaan elementti
      setTimeout(() => {
        setIsLoaded(true); // Animaatio näkyviin
        setStartAgain && setStartAgain(false); // Nollataan `startAgain`
      }, 50); // Viive, jotta animaatio voidaan käynnistää uudelleen
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAgain]);

  return (
    <div className={`flex items-center justify-center gap-2 ${type === 'row' ? 'flex-row' : 'flex-col'} `}>
      {type !== 'textonly' && (
        <img
          src={logo}
          alt="logo icon"
          width={logoDimensions.logo.width * logoScale}
          height={logoDimensions.logo.height * logoScale}
          className={`transition-opacity duration-900 ease-out transform ${isLoaded ? 'animate-rotate' : 'opacity-0'}`}
        />
      )}
      <img
        src={text}
        alt="logo text"
        width={logoDimensions.text.width * textScale}
        height={logoDimensions.text.height * textScale}
        className={`transition-opacity duration-900 ease-out transform ${isLoaded ? 'animate-slideIn' : 'opacity-0'}`}
      />
    </div>
  );
};

export default Logo;
