import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { Typography } from '@mui/material';

const UKK = () => {
  const [expanded, setExpanded] = useState<number | null>(null);
  return (
    <div className="flex flex-col lg:justify-center gap-2 ">
      {questions.map((question, index) => (
        <Accordion key={index} expanded={index === expanded} onChange={() => setExpanded(index === expanded ? null : index)} style={{ borderRadius: '20px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
            style={{
              backgroundColor: '#43687e',
              color: 'white',
              fontWeight: 'bold',
              borderBottom: '1px solid #444',
              padding: '2px 20px',
              borderRadius: '5px',
            }}
          >
            <Typography className="text-md">{question.question}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '20px', margin: 10 }}>
            <Typography variant="subtitle2" className="text-gray-800 text-sm">
              {question.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default UKK;
const questions = [
  { question: 'Minulla ei ole internet-yhteyttä, voinko käyttää ObsCloudia?', answer: 'Kyllä, ObsCloud vaatii toimiakseen internet-yhteyden mutta voit käyttää ObsCloudia myös 4G-yhteydellä.' },
  {
    question: 'Kuinka kauan tallenteet pysyvät tallessa?',
    answer: 'Tallenteiden säilytysaika on 30 päivää.',
  },
  {
    question: 'Onko ObsCloudin käyttö turvallista?',
    answer:
      'ObsCloudin käyttö on turvallista. Palvelu on salattu ja käyttää turvallisia yhteyksiä. Käyttäjätunnukset ja salasanat ovat salattuja ja tallennettu turvallisesti sekä käyttäjätunnukset toimitetaan suoraan käyttäjälle, joten vain käyttäjä tietää ne.',
  },
  {
    question: 'Mitä tarvitsen käyttääkseni ObsCloudia?',
    answer: (
      <>
        ObsCloudin käyttöön tarvitset <strong>Teltonika reitittimen</strong> sekä <strong>Dahua IP-kameran</strong> sekä toimivan internet yhteyden tai 4G-liittymän.
      </>
    ),
  },
  {
    question: 'Minulle on asennettu ObsCloud, mutta en tiedä miten sitä käytetään. Mitä teen?',
    answer: (
      <>
        Asennusliike joka on sinulle asentanut ObsCloud palvelun auttaa sinua käyttöönotossa ja opastaa käytössä. Voit myös ottaa yhteyttä meihin palautelomakkeen kautta tai maahantuojaan{' '}
        <a href="https://www.fsm.fi/" className="text-primary underline">
          FSM Oy:n
        </a>
        .
      </>
    ),
  },
  {
    question: 'Miten voin katsoa kameroita ja tallenteita?',
    answer: (
      <>
        ObsCloud kameroita katsotaan <strong>Obseron</strong> sovelluksella. Sovellus on saatavilla Android ja iOS laitteille sekä tietokoneelle. Voit ladata puhelinsovelluksen{' '}
        <a href="https://play.google.com/store/apps/details?id=com.valvova.obseron3" className="text-primary underline">
          {' '}
          Google Playsta
        </a>{' '}
        tai{' '}
        <a href="https://apps.apple.com/app/id1533044338" className="text-primary underline">
          {' '}
          App Storesta{' '}
        </a>{' '}
        tai etäkäyttösovelluksen{' '}
        <a href="https://www.obseron.com/download?lang=fi" className="text-primary underline">
          {' '}
          Obseron sivustolta
        </a>
        .
      </>
    ),
  },
  {
    question: 'Miten voin tilata ObsCloudin?',
    answer: (
      <>
        <a href="https://www.fsm.fi/" className="text-primary underline">
          FSM Oy
        </a>{' '}
        on ObsCloudin jakelija Suomessa. Voit ottaa yhteyttä heihin ja he ohjaavat sinut lähimmän jälleenmyyjän luokse.
      </>
    ),
  },
  {
    question: 'Mikä on Obseron?',
    answer: (
      <>
        Obseron on suomalainen kameravalvontaohjelmisto, joka on suunniteltu erityisesti ammattilaisten tarpeisiin. Obseron on Valvova Oy:n kehittämä tuote, joka vastaa kameravalvonnan vaatimuksia ja
        on helppo käyttää. Obseron on saatavilla Android ja iOS laitteille sekä tietokoneelle.
      </>
    ),
  },
];
