// customerContext.js

import React, { useState, createContext, useContext } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './authContext';
export interface CustomerContextType {
  customerID: null;
  setCustomerID: React.Dispatch<React.SetStateAction<null>>;
  state: any;
  setState: React.Dispatch<React.SetStateAction<number>>;
  loadingActive: any;
  loadingText: any;
  customer: CustDB | null;
  siteDone: boolean;
  setSiteDone: React.Dispatch<React.SetStateAction<boolean>>;
  resetState: () => void;
}

export const CustomerContext = createContext<CustomerContextType | null>({
  customerID: null,
  setCustomerID: () => {},
  state: 0,
  setState: () => {},
  loadingActive: false,
  loadingText: '',
  customer: null,
  siteDone: false,
  setSiteDone: () => {},
  resetState: () => {},
});

interface BasicInfoType {
  name: string;
  street: string;
  city: string;
  postalCode: string;
}

interface ContactPersonType {
  name: string;
  email: string;
  phone: string;
}

interface Camera {
  name: string;
  location: string;
}

interface RemoteUserType {
  name: string;
  email: string;
  phone: string;
}

interface Customer {
  createdAt: Date;
  owner: string;
  basicInfo: BasicInfoType;
  contactPerson: ContactPersonType;
  cameras: Camera[];
  remoteUsers: RemoteUserType[];
  targerID: number;
}

interface CustDB {
  owner: string;
  downloadURL: string;
  Customer: Customer;
}

export const CustomerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [customerID, setCustomerID] = useState(null);
  const [state, setState] = useState(0);
  const [loadingActive, setLoadingActive] = useState(false);
  const [loadingText, setLoadingText] = useState('Loading');
  const [customer, setCustomer] = useState<CustDB | null>(null);
  const [siteDone, setSiteDone] = useState(false);
  const { currentUser } = useAuth();
  useEffect(() => {
    if (customerID) {
      setState(1);
    }
  }, [customerID]);

  const resetState = () => {
    setCustomerID(null);
    setState(0);
    setLoadingActive(false);
    setLoadingText('');
    setCustomer(null);
    setSiteDone(false);
  };

  useEffect(() => {
    if (state >= 1 && customerID) {
      setLoadingActive(true);
      const interval = setInterval(async () => {
        axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/status/${customerID}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${await currentUser.getIdToken()}`,
            },
          })
          .then((response) => {
            if (response.data === 'Initializing') {
              setState(2);
              setLoadingText('Valmistellaan konfiguraatiota palvelimelle');
            }
            if (response.data === 'Luodaan asiakasta') {
              setState(3);
              setLoadingText('Luodaan kohdetta');
            }
            if (response.data === 'Valmistellaan palvelinta') {
              setState(4);
              setLoadingText('Valmistellaan palvelinta');
            }
            if (response.data === 'Valmistellaan reititintä') {
              setState(5);
              setLoadingText('Luodaan turvalliset reititykset');
            }
            if (response.data === 'Luodaan kameroita') {
              setState(6);
              setLoadingText('Luodaan kameroita');
            }
            if (response.data === 'Luodaan tietokantaa') {
              setState(7);
              setLoadingText('Luodaan tietokantaa');
            }
            if (response.data === 'Konfiguroidaan kameroita palvelimelle') {
              setState(8);
              setLoadingText('Konfiguroidaan kameroita palvelimelle');
            }
            if (response.data === 'Luodaan etäkäyttäjiä') {
              setState(9);
              setLoadingText('Luodaan etäkäyttäjiä palvelimelle');
            }
            if (response.data === 'Käsitellään maksua') {
              setState(10);
              setLoadingText('Käsitellään maksutietoja');
            }
            if (response.data === 'Virhe') {
              setState(11);
              setLoadingText(
                'Tapahtui virhe! Koita hetken päästä uudestaan tai ota yhteyttä asiakaspalveluun, jos ongelma jatkuu. Maksua ei ole veloitettu.'
              );
              clearInterval(interval);
              setTimeout(() => {
                setLoadingActive(false);
                setSiteDone(true);
              }, 2000);
            }
            if (response.data === 'Valmis') {
              setState(12);
              setLoadingText('Valmista on!');
              // Jos valmis niin pysäytä pollaus välittömästi
              clearInterval(interval);

              // Aseta timeout, joka odottaa sekunnin ennen kuin pysäyttää pollauksen
              setTimeout(() => {
                setLoadingActive(false);
                setSiteDone(true);
              }, 2000);
            }
          })
          .catch((error) => {
            console.error('There was an error!', error);
          });
      }, 100);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, customerID]);

  return (
    <CustomerContext.Provider
      value={{
        customerID,
        setCustomerID,
        state,
        setState,
        loadingActive,
        loadingText,
        customer,
        siteDone,
        setSiteDone,
        resetState,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};
export default CustomerProvider;
// useCustomer
export const useCustomer = () => {
  return useContext(CustomerContext);
};
