type Props = {
    size?:number
    fill?:string | "black"
  
}

export const Logo = ({size,fill="black"}:Props) => {
    
  
 

    return (<svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                style={{
                    width: "100%", 
                    height: "auto", 
                  }}                
                viewBox={`0 0 ${620} ${80}`}
                
              
                >
            <defs id="defs28"/>
            <path
                fill={fill}
                opacity="1"
                stroke="none"
                d="m 136.93552,38.862307 c 7.4371,8.822038 5.80047,20.308735 -1.23288,25.402806 -2.20981,1.600488 -4.92783,3.39273 -7.4711,3.483907 -10.75711,0.385709 -21.53603,0.163455 -32.531085,0.163455 0,-6.087347 0,-11.669772 0,-17.626105 3.565791,0 6.933075,0 10.541035,0 0.17874,2.029107 0.35083,3.982633 0.58021,6.586609 6.44775,0 12.88335,0.197661 19.29833,-0.08021 3.40267,-0.147329 4.58792,-2.476402 4.36013,-5.917874 -0.24108,-3.643073 -1.99475,-4.91785 -5.41864,-4.893315 -9.62481,0.06899 -19.2505,0.02409 -29.226855,0.02409 0,-11.395191 0,-22.409853 0,-33.951663 9.590185,0 19.096215,-0.754669 28.423575,0.212954 9.27203,0.961818 16.45074,11.092144 12.67004,23.089431 -0.31311,0.993587 -0.19079,2.124391 0.007,3.505952 m -17.42411,-4.876469 c 2.80948,-0.404876 6.67166,1.291935 7.00421,-3.854157 0.24009,-3.715 -0.36148,-6.844553 -4.74465,-7.08836 -4.90332,-0.272705 -9.8335,-0.06266 -14.94324,-0.06266 0,3.913673 0,7.266215 0,11.005019 4.12979,0 7.91858,0 12.68368,1.14e-4 z"
                id="path1"
                style={{ strokeWidth: 1 }} />
            <path
                fill={fill}
                opacity="1"
                stroke="none"
                d="m 565.35303,23.752191 c -0.30292,7.509282 -0.30292,14.621385 -0.30292,21.979144 -3.86914,0 -7.15753,0 -10.81311,0 0,-10.96931 0,-22.006614 0,-33.611602 10.90753,0 21.91846,-0.919157 32.68994,0.291412 8.5846,0.964824 13.12763,7.436565 13.78241,18.125878 0.4931,8.051569 -0.19012,16.224618 -0.9447,24.287883 -0.62879,6.719163 -7.48444,12.885458 -14.30603,13.062117 -10.30561,0.266849 -20.62335,0.06963 -31.26184,0.06963 0,-5.953064 0,-11.672404 0,-17.714765 3.47131,0 6.84845,0 10.48217,0 0.14985,2.17771 0.28309,4.113162 0.45344,6.588594 6.50775,0 12.9533,0.188237 19.37854,-0.09063 2.65313,-0.115141 4.44165,-1.695282 4.36798,-4.953777 -0.17304,-7.655881 -0.21619,-15.322728 0.003,-22.976118 0.1131,-3.946351 -1.9267,-5.64139 -5.29016,-5.721011 -5.97315,-0.141388 -11.95661,0.153586 -18.23871,0.663212 z"
                id="path2"
                style={{ strokeWidth: 1 }}  />
            <path
                fill={fill}
                opacity="1"
                stroke="none"
                d="m 21.235495,21.872486 c 4.34449,-6.812083 9.92112,-10.598419 17.97681,-9.940713 2.48319,0.202763 5.01238,0.212683 7.49397,4.93e-4 11.39115,-0.974063 19.10024,5.973676 19.78502,18.548501 0.43036,7.902879 -0.15433,15.915665 -0.93626,23.814543 -0.7784,7.863138 -7.4437,13.478983 -15.2755,13.641312 -4.83073,0.100102 -9.6687,0.13473 -14.49731,-0.0083 -9.09951,-0.268422 -16.25437,-8.334252 -15.5869,-18.037391 2.39316,0 4.89952,-0.327451 7.25886,0.120119 1.46794,0.278474 2.83402,1.598487 4.02399,2.692386 1.13354,1.04199 1.91577,3.383611 2.96184,3.450811 5.75085,0.36935 11.54771,0.415429 17.29661,0.05673 1.02704,-0.06415 2.67533,-2.52198 2.72139,-3.917979 0.26364,-7.990032 0.022,-15.995285 0.17812,-23.99096 0.0744,-3.806152 -2.1611,-5.057583 -5.23714,-5.220853 -4.15413,-0.220465 -8.33771,-0.208354 -12.49331,0.0045 -5.04342,0.257733 -5.99384,4.046515 -6.13738,8.06566 -0.17062,4.777749 -0.0401,9.566254 -0.0401,14.590739 -3.77616,0 -7.05369,0 -11.60464,0 0.64624,-7.909092 1.2849,-15.72542 2.11198,-23.869613 z"
                id="path3"
                style={{ strokeWidth: 1 }}  />
            <path
                fill={fill}
                opacity="1"
                stroke="none"
                d="m 173.16699,32.636993 c -3.20462,-10.233801 6.54663,-20.301067 15.00119,-20.667707 0.83112,-0.036 1.6651,-0.0081 2.49774,-0.0081 7.32151,-2.79e-4 14.64301,-1.81e-4 22.32694,-1.81e-4 0,3.5846 0,6.988618 0,11.054883 -7.27805,0 -14.6954,-0.0668 -22.11084,0.02392 -4.80438,0.05871 -7.67059,2.516421 -7.16559,6.304545 0.36099,2.707948 1.43201,4.780709 5.11575,4.701055 5.42618,-0.117337 10.91272,0.204532 16.29523,0.92459 10.29083,1.37668 13.73517,8.200419 13.78992,15.929601 0.0628,8.865553 -4.88562,15.828063 -13.80792,16.791508 -9.6807,1.045284 -19.5615,0.236959 -29.56012,0.236959 0,-3.742457 0,-7.016179 0,-11.028354 8.49063,0 16.919,0.03413 25.34683,-0.01712 3.28952,-0.01998 6.22531,-0.235572 6.4986,-4.878708 0.27496,-4.671746 -1.55262,-6.112392 -5.98054,-6.09955 -5.26229,0.01518 -10.55111,-0.364586 -15.78311,-0.966883 -5.91406,-0.68082 -10.99936,-5.823496 -12.46408,-12.300306 z"
                id="path4"
                style={{ strokeWidth: 1 }}  />
            <path
                fill={fill}
                opacity="1"
                stroke="none"
                d="m 435.93201,53.995993 c 0.10785,-8.598094 -0.0885,-16.759389 0.20062,-24.903409 0.16052,-4.523679 -2.14471,-5.922729 -5.96076,-6.058646 -3.15759,-0.112529 -6.32263,-0.03316 -9.48425,-0.01496 -6.58185,0.03799 -8.43072,1.878481 -8.53613,8.666202 -0.0718,4.620035 -0.0137,9.242138 -0.0137,14.042523 -3.85877,0 -7.28931,0 -11.48224,0 0.48022,-7.179731 0.45868,-14.148173 1.53271,-20.943498 1.16425,-7.366584 8.13312,-12.669324 15.48395,-12.808215 3.49353,-0.06599 7.00739,0.216168 10.48047,-0.05868 11.43091,-0.904727 19.04321,5.946914 19.79523,18.480609 0.48272,8.046727 -0.18836,16.212878 -0.94599,24.268693 -0.65668,6.983227 -7.53357,12.971081 -14.56305,13.252823 -5.31665,0.213106 -10.66046,0.28006 -15.97058,1.88e-4 -8.53515,-0.449833 -15.57709,-8.91731 -14.87176,-17.994924 2.4281,0 4.95495,-0.320891 7.34137,0.119326 1.45105,0.267663 2.81799,1.570718 3.97369,2.670348 0.87433,0.831934 1.11572,2.416387 2.05353,3.078562 3.33465,2.354491 17.32391,1.317899 20.96692,-1.796882 z"
                id="path5"
                style={{ strokeWidth: 1 }}  />
            <path
                fill={fill}
                opacity="1"
                
                stroke="none"
                d="m 259.28632,36.730876 c 0.001,4.476683 -0.12095,8.478027 0.0324,12.468819 0.22094,5.749907 2.24176,7.640199 7.92587,7.703766 3.16354,0.03539 6.32861,0.04252 9.49173,-0.01301 5.3837,-0.09441 6.10803,-0.793701 6.78781,-6.622434 3.68915,0 7.42874,0 11.18484,0 0.51685,10.50718 -8.05523,19.147506 -19.77877,17.733311 -2.79019,-0.336602 -5.65589,-0.04078 -8.48679,-0.04815 -9.59998,-0.02524 -16.91006,-5.258761 -17.80869,-14.900829 -0.86852,-9.319403 -0.29483,-18.8954 0.75916,-28.232962 0.83545,-7.401194 8.0639,-12.680858 15.45676,-12.849353 4.16092,-0.09495 8.32571,-0.0233 12.48874,-0.02314 9.40176,5.21e-4 15.34164,5.136936 16.94668,14.594004 0.15793,0.930446 0.59085,1.81424 1.03266,3.124759 -4.00382,0 -7.72736,0 -11.82245,0 0.49673,-5.996032 -3.30912,-6.959874 -8.18402,-6.666724 -1.99112,0.119749 -3.99543,0.01952 -5.99375,0.02049 -8.45715,0.0045 -10.01214,1.590645 -10.03164,10.233867 -0.002,0.99917 -4.6e-4,1.998381 -5.5e-4,3.477606 z"
                id="path6"
                style={{ strokeWidth: 1}}  />
            <path
                fill={fill}
                opacity="1"
                stroke="none"
                d="m 505.87897,67.961969 c -4.29486,-7.85e-4 -8.12506,0.115947 -11.94574,-0.02577 -8.91193,-0.330661 -16.14685,-8.591599 -15.43634,-18.012529 2.58569,0 5.2431,-0.232177 7.8266,0.103783 1.18621,0.154298 2.27325,1.368145 3.30639,2.221265 0.31226,0.257841 0.23139,0.945707 0.4347,1.388791 1.42736,3.110385 12.05944,4.793215 18.99841,3.164917 4.26349,-1.000486 3.93243,-4.126042 3.91309,-7.37689 -0.0731,-12.282214 -0.0294,-24.565154 -0.0294,-37.154064 4.11096,0 7.81762,0 11.03204,0 0,14.633221 0.49762,29.020328 -0.24121,43.343635 -0.30206,5.856026 -7.37494,13.293104 -17.85858,12.346902 z"
                id="path7"
                style={{ strokeWidth: 1 }}  />
            <path
                fill={fill}
                opacity="1"
                stroke="none"
                d="m 367.83902,56.909171 c 2.9686,-0.277278 4.6915,0.173859 4.37863,3.355167 -0.22577,2.295382 -0.0441,4.630864 -0.0441,7.688631 -8.9169,0 -17.63733,-0.152489 -26.34942,0.05173 -6.19367,0.145106 -12.17377,-0.397774 -15.87079,-5.960548 -2.16907,-3.263713 -4.39081,-7.222086 -4.55374,-10.956798 -0.55719,-12.769823 -0.21353,-25.578915 -0.21353,-38.750376 3.58398,0 6.96948,0 11.01858,0 0,9.396189 -4.3e-4,18.802206 3.1e-4,28.208253 2.1e-4,2.826113 -0.0904,5.656066 0.0251,8.477549 0.24869,6.077114 2.11777,7.857573 8.19602,7.88141 7.64703,0.03002 15.29425,0.0068 23.41293,0.0056 z"
                id="path8"
                style={{ strokeWidth: 1 }}  />
            <path
                fill={fill}
                opacity="1"
                stroke="none"
                d="m 478.01801,44.140729 c -0.0115,-10.756725 -0.0115,-21.055496 -0.0115,-31.775416 3.61932,0 6.98291,0 10.70429,0 0,11.05466 0,22.084033 0,34.138303 -3.69355,-0.658725 -7.18744,-1.281837 -10.69281,-2.362887 z"
                id="path9"
                style={{ strokeWidth: 1 }}  />
        </svg>
 )
    }