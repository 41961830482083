import React, { useState } from 'react';
import { useAuth } from '../../../context/authContext';
import { useToast } from '../../../context/toastContext';
import { Input, Card, CardBody, Typography } from '@material-tailwind/react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
const Register = () => {
  const { registerWithEmailAndPassword } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  const handleRegister = async () => {
    setLoading(true);
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      showToast('Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character');
      return;
    }
    if (password !== confirmPassword) {
      showToast('Passwords do not match, please try again');
      setPasswordError(true);
      setLoading(false);
      return;
    }

    await registerWithEmailAndPassword(email, password);
    setLoading(false);
  };

  return (
    <Card placeholder={''} className="flex justify-center items-center pb-6   ">
      <CardBody placeholder={''} className="flex flex-col  w-full max-w-[500px] p-4">
        <Typography placeholder={''} variant="h4" className="text-center mb-4">
          Rekisteröi tili
        </Typography>
        <Typography placeholder={''} variant="paragraph" className="text-center text-gray-600 mb-8 p-6">
          Luo tili ObsCloud palveluun ja aloita kameravalvonnan hallinta nyt.
        </Typography>
        <form>
          <div className="flex flex-col gap-6 mb-4 justify-center items-center">
            <div className="w-4/5 gap-6 flex flex-col mb-4">
              <Input type="email" label="Sähköposti" variant="standard" color="light-blue" onChange={(e) => setEmail(e.target.value)} crossOrigin={true} />
              <Input type="password" label="Salasana" variant="standard" color="light-blue" onChange={(e) => setPassword(e.target.value)} error={passwordError} crossOrigin={true} />
              <Input type="password" label="Vahvista salasana" variant="standard" color="light-blue" onChange={(e) => setConfirmPassword(e.target.value)} error={passwordError} crossOrigin={true} />
              {passwordError && (
                <Typography placeholder={''} variant="small" color="red">
                  Passwords do not match.
                </Typography>
              )}
            </div>
            <div className="w-full items-center flex justify-center hover:scale-105 ease-in-out duration-300">
              <Button
                disabled={loading}
                variant="contained"
                className="w-4/5 mt-4"
                sx={{
                  padding: '10px 20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  fontFamily: 'poppins',
                  borderRadius: 30,
                  fontWeight: 600,

                  color: 'white',
                  backgroundColor: '#43687e',
                  '&:hover': {
                    backgroundColor: '#2c4f5a',
                  },
                }}
                onClick={handleRegister}
              >
                Rekisteröidy nyt
              </Button>
            </div>
            <div className="w-full items-center flex justify-center hover:underline">
              <Link to="/login">Minulla on jo tili!</Link>
            </div>
          </div>
        </form>
      </CardBody>
    </Card>
  );
};

export default Register;
