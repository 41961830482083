import { User } from 'firebase/auth';
import { User as UserType } from '../types/types';

export const getUserData = async ({ currentUser }: { currentUser: User }) => {
  const token = await currentUser.getIdToken();

  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/get-user/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        uid: currentUser.uid,
        email: currentUser.email,
      }),
    });

    const { user } = await response.json();

    return user as UserType;
  } catch (error) {
    console.log(error);
  }
};

export const getSubscriptions = async ({ currentUser, billingID }: { currentUser: User; billingID: string }) => {
  const token = await currentUser.getIdToken();

  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/get-user-subscriptions/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'access-control-allow-origin': '*',
      },
      body: JSON.stringify({ billingID: billingID }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserData = async ({ currentUser }: { currentUser: User }) => {
  const token = await currentUser.getIdToken();
  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/update-user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ uid: currentUser.uid }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};
export async function updatePaymentCard({ paymentMethodId, customerID, currentUser }: { paymentMethodId: string; customerID: string; currentUser: any }) {
  const token = await currentUser.getIdToken();

  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/update-payment-card`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ paymentMethodId, customerID }),
  });
  return response.json();
}
