import React, { useState } from 'react';
import { useAuth } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import ResetPassword from './resetPassword';
import { Input, Card, CardBody, Typography } from '@material-tailwind/react';
import { Button } from '@mui/material';
import Modal from '../modal/modal';

const Login = () => {
  const { loginWithEmailAndPassword } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { user, userData } = await loginWithEmailAndPassword(email, password);
      if (user && userData) {
        if (userData && userData.role === 'ADMIN') {
          navigate('/admin');
        } else if (userData && userData.role === 'RESELLER') {
          navigate('/reseller');
        } else if (userData && userData.role === 'USER') {
          navigate('/dashboard');
        }
      }
    } catch (error) {
      setError('Failed to log in!');
    }
  };

  return (
    <div className="flex items-center justify-center  h-full ">
      <div id="recaptcha-container-login"></div>
      <Modal title="Salasanan palautus" show={resetDialogOpen} onClose={() => setResetDialogOpen(false)}>
        <ResetPassword resetDialogOpen={resetDialogOpen} setResetDialogOpen={setResetDialogOpen} />
      </Modal>
      <Card placeholder={''} className="w-full max-w-md space-y-8 p-6 bg-white shadow-xl rounded-lg">
        <CardBody>
          <div className="text-center mb-6 p-6">
            <Typography placeholder={''} variant="h5" color="gray">
              Kirjaudu ObsCloud -palveluun
            </Typography>
          </div>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="flex flex-col justify-center gap-8 w-full">
              <Input type="email" color="light-blue" size="lg" variant="standard" label="Sähköposti" onChange={(e) => setEmail(e.target.value)} crossOrigin="true" />
              <Input type="password" color="light-blue" size="lg" variant="standard" label="Salasana" onChange={(e) => setPassword(e.target.value)} crossOrigin="true" />
            </div>
            {/* Dont remember password */}

            {error && <p className="text-red-500 text-sm text-center">{error}</p>}
            <div className="w-full items-center flex justify-center hover:scale-105 ease-in-out duration-300">
              <Button
                placeholder={''}
                variant="contained"
                type="submit"
                sx={{
                  minWidth: '200px',
                  padding: '10px 20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  fontFamily: 'poppins',
                  borderRadius: 30,
                  fontWeight: 600,

                  color: 'white',
                  backgroundColor: '#43687e',
                  '&:hover': {
                    backgroundColor: '#2c4f5a',
                  },
                }}
              >
                Kirjaudu sisään
              </Button>
            </div>
            <div className="flex items-center justify-center gap-6">
              {/* <p className="text-sm">
                <Link to="/register" className="font-medium hover:underline">
                  Eikö sinulla ole tiliä?
                </Link>
              </p> */}
              <div className="text-sm">
                <p
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setResetDialogOpen(true);
                  }}
                  className="font-medium hover:underline cursor-pointer"
                >
                  Unohditko Salasanasi?
                </p>
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default Login;
