import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import RenderImage from './renderImage';
import obseron from '../../../assets/pictures/obseron_icon.webp';
import image1 from '../../../assets/guides/obs1.jpg';
import image2 from '../../../assets/guides/obs2.jpg';
import image3 from '../../../assets/guides/obs3.jpg';
import image4 from '../../../assets/guides/obs4.jpg';
import image5 from '../../../assets/guides/obs5.jpg';
import image6 from '../../../assets/guides/obs6.jpg';
import image7 from '../../../assets/guides/obs7.jpg';
import image8 from '../../../assets/guides/obs8.jpg';
import image9 from '../../../assets/guides/obs9.jpg';
type ImageAnimationProps = {
  top: string;
  left: string;
  width: string;
  height: string;
};
type TooltipProps = {
  title: string;
  style: TooltipStyle;
  phase?: number;
  placement?: 'top' | 'bottom' | 'left' | 'right';
};
type TooltipStyle = {
  top: string;
  left: string;
  width: string;
  height: string;
};

type RenderImageProps = {
  src: string;
  alt: string;
  text?: string;
  className?: any;
  imageAnimationProps?: ImageAnimationProps;
  helperText?: string;
  credentials?: boolean;
  tooltip?: TooltipProps[];
  phases?: boolean;
};
const imageProps: RenderImageProps[] = [
  {
    src: image1,
    alt: 'Kirjautumisnäkymä',
    text: '1. Aloita asennus',
    phases: true,
    tooltip: [
      {
        title: 'Valitse seuraava',
        style: { top: '90%', left: '65%', width: '16%', height: '8%' },
        placement: 'top',
        phase: 1,
      },
    ],
  },
  {
    src: image2,
    alt: 'Yhteysnäkymä',
    text: '2. Hyväksy käyttöehdot',
    phases: true,
    tooltip: [
      {
        title: 'Hyväksy käyttöehdot',
        style: { top: '90%', left: '65%', width: '16%', height: '8%' },
        placement: 'top',
        phase: 1,
      },
    ],
  },
  {
    src: image3,
    alt: 'Yhteysnäkymä',
    text: '3. Valitse ominaisuudet',
    phases: true,
    tooltip: [
      {
        title: 'Voit valita automaattisen käynnistyksen mikäli haluat että ohjelma käynnistyy automaattisesti tietokoneen käynnistyessä',
        style: { top: '40%', left: '33%', width: '30%', height: '8%' },
        placement: 'top',
      },
      {
        title: 'Valitse seuraava',
        style: { top: '90%', left: '65%', width: '16%', height: '8%' },
        placement: 'top',
        phase: 1,
      },
    ],
  },
  {
    src: image4,
    alt: 'Yhteysnäkymä',
    text: '4. Valitse asennuskansio ja aloita asennus',
    phases: true,
    tooltip: [
      {
        title: 'Valitse asennuskansio ja aloita asennus',
        style: { top: '90%', left: '65%', width: '16%', height: '8%' },
        placement: 'top',
        phase: 1,
      },
    ],
  },
  {
    src: image5,
    alt: 'Yhteysnäkymä',
    text: '5. Asennus valmis',
    phases: true,
    tooltip: [
      {
        title: 'Asennus on nyt valmis',
        style: { top: '90%', left: '65%', width: '16%', height: '8%' },
        placement: 'top',
        phase: 1,
      },
    ],
  },
  {
    src: image6,
    alt: 'Yhteysnäkymä',
    text: '6. Aloitusnäkymä',
  },
  {
    src: image7,
    alt: 'Yhteysnäkymä',
    text: '7. Valitse asetukset',
    phases: true,
    tooltip: [
      {
        title: 'Valitse ensin (...) -painike',
        style: { top: '3%', left: '7%', width: '4%', height: '4%' },
        placement: 'top',
        phase: 1,
      },
      {
        title: 'Valitse sitten asetukset',
        style: { top: '11%', left: '8%', width: '10%', height: '3%' },
        placement: 'bottom',
        phase: 2,
      },
    ],
  },

  {
    src: image8,
    alt: 'Yhteysnäkymä',
    text: '8. Valitse Palvelimet',
    phases: true,
    tooltip: [
      {
        title: 'Valitse Palvelimet',
        style: { top: '33%', left: '15%', width: '10%', height: '3.5%' },
        placement: 'top',
        phase: 1,
      },
      {
        title: 'Valitse + Lisää palvelin',
        style: { top: '64%', left: '30.5%', width: '3.5%', height: '3.5%' },
        placement: 'top',
        phase: 2,
      },
    ],
  },
  {
    src: image9,
    alt: 'Yhteysnäkymä',
    text: '9. Aseta etäkäytön tunnukset ja yhdistä',
    phases: true,
    tooltip: [
      {
        title: 'Valitse juuri luotu palvelin',
        style: { top: '38.5%', left: '29%', width: '10%', height: '2%' },
        placement: 'top',
        phase: 1,
      },
      {
        title: 'Aseta nimi tai jätä oletusarvo',
        style: { top: '30.5%', left: '46%', width: '14%', height: '2%' },
        placement: 'top',
        phase: 2,
      },
      {
        title: 'Aseta palvelimen osoite',
        style: { top: '34%', left: '46%', width: '14%', height: '2%' },
        placement: 'top',
        phase: 3,
      },
      {
        title: 'Aseta portti',
        style: { top: '36.5%', left: '48%', width: '8%', height: '2%' },
        placement: 'top',
        phase: 4,
      },
      {
        title: 'Aseta käyttäjätunnus',
        style: { top: '39%', left: '46%', width: '14%', height: '2%' },
        placement: 'top',
        phase: 5,
      },
      {
        title: 'Aseta salasana',
        style: { top: '41.5%', left: '46%', width: '14%', height: '2%' },
        placement: 'top',
        phase: 6,
      },
      {
        title: 'Yhdistä',
        style: { top: '49%', left: '51%', width: '7%', height: '2%' },
        placement: 'top',
        phase: 7,
      },
      {
        title: "Jos yhteys onnistui, näet 'Yhdistetty' -tilan",
        style: { top: '30.4%', left: '73%', width: '13%', height: '2%' },
        placement: 'top',
        phase: 8,
      },
    ],
  },
];

const DownloadPCApp = () => {
  return (
    <div className="flex flex-col items-center mt-10">
      <Typography variant="h6" className="text-center text-blue-gray-700">
        Lataa Obseron tietokoneellesi
      </Typography>

      <div className="flex flex-row gap-4 pt-4 items-center w-full">
        <a
          href="https://www.obseron.com/download?lang=fi"
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-row gap-4 pt-4 items-center w-full justify-evenly hover:scale-110 transition-transform duration-300"
        >
          <div className="flex flex-row items-center w-1/2  justify-center">
            <img src={obseron} alt="Obseron for PC download" className="w-28  rounded-2xl shadow-lg" />
          </div>
          <Typography variant="body2" className="text-blue-gray-600 w-1/2">
            Voit ladata sovelluksen suoraan Obseron.com -sivustolta. Kun olet ladannut sovelluksen, seuraa asennusohjeita ja aloita käyttö.
          </Typography>
        </a>
      </div>
    </div>
  );
};

const Setup = ({ currentImage }: { currentImage: { currentImageIndex: number | null; setCurrentImageIndex: any } }) => {
  return (
    <div className="flex flex-col items-center mt-10">
      <Typography variant="h6" className="text-center text-blue-gray-700">
        Asennusohjeet
      </Typography>
      <div className="flex flex-row gap-2 w-full justify-evenly items-start m-2">
        {imageProps.slice(0, 3).map((image, index) => (
          <RenderImage key={index} imageProps={imageProps} initialIndex={index} currentImage={currentImage} />
        ))}
      </div>
      <div className="flex flex-row gap-2 w-full justify-evenly items-start m-2">
        {imageProps.slice(0, 3).map((image, index) => (
          <RenderImage key={index} imageProps={imageProps} initialIndex={index + 3} currentImage={currentImage} />
        ))}
      </div>
      <div className="flex flex-row gap-2 w-full justify-evenly items-start m-2">
        {imageProps.slice(0, 3).map((image, index) => (
          <RenderImage key={index} imageProps={imageProps} initialIndex={index + 6} currentImage={currentImage} />
        ))}
      </div>
    </div>
  );
};

const Tietokone = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  return (
    <div className="p-8 flex flex-col items-center bg-gray-50 rounded-lg shadow-lg">
      <Typography variant="h5" className="text-center font-bold text-blue-gray-800 italic pb-6">
        Obseron käyttöönotto tietokoneella{' '}
      </Typography>
      <Typography variant="body1" className="text-center mb-6 px-12 leading-relaxed">
        Saitko sähköpostiisi kutsun ObsCloud-pilvipalveluun? Seuraa alla olevia ohjeita ottaaksesi Obseron sovelluksen käyttöösi.
      </Typography>
      <Divider className="w-4/5 p-6" />
      <DownloadPCApp />
      <Setup currentImage={{ currentImageIndex, setCurrentImageIndex }} />
    </div>
  );
};

export default Tietokone;
