import { features } from './content';
import { Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export const Features = () => {
  return (
    <>
      {features.map((feature, index) => (
        <div key={index} className=" flex-row flex p-4  md:p-0">
          <div className=" border border-greyBorder h-8  my-2 rounded-lg  sm:h-8 xl:h-12 hover:scale-125 ease-in-out duration-300 shadow-md hover:drop-shadow-[0_35px_35px_rgba(0,0,0,0.55)] ">
            <CheckIcon sx={{ fontSize: { md: 32, lg: 48 } }} className="text-almostBlack" />
          </div>
          <div className="flex flex-col px-5 pr-2 gap-4">
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: { xs: '1rem', sm: '1rem', md: '1.15rem' },
              }}
              variant="h6"
            >
              {feature.title}
            </Typography>

            <Typography
              className="text-gray-800"
              sx={{
                fontFamily: 'open-sans',
                lineHeight: '120%',
                fontSize: { xs: '0.75rem', sm: '0.875rem', md: '0.965rem' },
              }}
              variant="body2"
            >
              {feature.description}
            </Typography>
          </div>
        </div>
      ))}
    </>
  );
};
