import { Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useToast } from '../../context/toastContext';
const EmailVerifiedPage = () => {
  const [params] = useSearchParams();
  const { showToast } = useToast();
  const uid = params.get('uid');
  if (!uid) {
    showToast('Invalid link', { type: 'error' });
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <div className="flex flex-col items-center justify-center">Invalid link</div>{' '}
      </div>
    );
  }
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="flex flex-col items-center justify-center">
        {/*  <img src="/images/verified.svg" alt="Email verified" /> */}
        <Typography
          sx={{
            color: '#333333',
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: '1.5rem',
          }}
          variant="h6"
        >
          Sähköpostisi on nyt vahvistettu
        </Typography>
        <Typography
          sx={{
            color: '#666666',
            lineHeight: '1.3',
            fontweight: 600,
            fontFamily: 'open-sans',
            fontSize: '0.875rem',
          }}
          variant="body2"
        >
          Voit nyt kirjautua sulkea tämän ikkunan.
        </Typography>
      </div>
    </div>
  );
};

export default EmailVerifiedPage;
