import React from 'react';
import { TextField, Button } from '@mui/material';
import { useAuth } from '../../../context/authContext';
type Customer = {
  accountID: string;
  email: string;
  phone: string;
  address: string;
  name: string;
  country: string;
  vatNumber: string;
};

type ErrorMessages = {
  accountID: string;
  email: string;
  phone: string;
  address: string;
  name: string;
  country: string;
  vatNumber: string;
};

const NewCustomer = ({ handleAddNewCustomer }: any) => {
  const [errorMessages, setErrorMessages] = React.useState<ErrorMessages>({
    accountID: '',
    email: '',
    phone: '',
    address: '',
    name: '',
    country: '',
    vatNumber: '',
  });
  const [customer, setCustomer] = React.useState<Customer>({
    accountID: '',
    email: '',
    phone: '',
    address: '',
    name: '',
    country: '',
    vatNumber: '',
  });
  const [Loading, setLoading] = React.useState(false);
  const { currentUser } = useAuth();
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setCustomer((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (validateForm(customer)) {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/create-billing-customer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await currentUser?.getIdToken()}`,
        },
        body: JSON.stringify({ billingCustomer: customer }),
      });
      const data = await response.json();
      if (response.status === 200) {
        setLoading(false);
        handleAddNewCustomer();
      }
    } else {
    }

    setLoading(false);
  };
  const emailRegex = /\S+@\S+\.\S+/;
  const validateForm = (customer: any) => {
    let isValid = true;
    let errors = {
      accountID: '',
      email: '',
      phone: '',
      address: '',
      name: '',
      country: '',
      vatNumber: '',
    };
    if (!customer.accountID || customer.accountID.length < 3) {
      errors.accountID = 'Asiakasnumero on pakollinen';
      isValid = false;
    }
    if (!customer.email || !emailRegex.test(customer.email)) {
      errors.email = 'Virheellinen sähköposti';
      isValid = false;
    }

    if (!customer.phone || customer.phone.length < 8) {
      errors.phone = 'Puhelinnumero on pakollinen ja vähintään 8 merkkiä pitkä';
      isValid = false;
    }
    if (!customer.address || customer.address.length < 6) {
      errors.address = 'Osoite on pakollinen ja vähintään 6 merkkiä pitkä';
      isValid = false;
    }
    if (!customer.name || customer.name.length < 3) {
      errors.name = 'Nimi on pakollinen ja vähintään 3 merkkiä pitkä';
      isValid = false;
    }
    if (!customer.country) {
      errors.country = 'Maa on pakollinen';
      isValid = false;
    }
    if (!customer.vatNumber) {
      errors.vatNumber = 'Y-tunnus on pakollinen';
      isValid = false;
    }

    setErrorMessages(errors);
    return isValid;
  };

  return (
    <div className="flex flex-1 w-full justify-center ">
      <form onSubmit={handleSubmit} className="flex flex-col w-2/3 gap-5 justify-center">
        <TextField
          variant="standard"
          type="text"
          label="Asiakasnumero"
          error={errorMessages.accountID ? true : false}
          helperText={errorMessages.accountID}
          name="accountID"
          value={customer.accountID}
          onChange={handleChange}
        />

        <TextField
          variant="standard"
          type="text"
          label="Nimi"
          error={errorMessages.name ? true : false}
          helperText={errorMessages.name}
          required={errorMessages.name ? true : false}
          name="name"
          value={customer.name}
          onChange={handleChange}
        />
        <TextField
          variant="standard"
          type="text"
          label="Sähköposti"
          error={errorMessages.email ? true : false}
          helperText={errorMessages.email}
          required={errorMessages.email ? true : false}
          name="email"
          value={customer.email}
          onChange={handleChange}
        />
        <TextField
          variant="standard"
          type="text"
          label="Puhelin"
          required={errorMessages.phone ? true : false}
          error={errorMessages.phone ? true : false}
          name="phone"
          value={customer.phone}
          onChange={handleChange}
        />
        <TextField
          variant="standard"
          type="text"
          error={errorMessages.address ? true : false}
          required={errorMessages.address ? true : false}
          label="Osoite"
          name="address"
          value={customer.address}
          onChange={handleChange}
        />

        <TextField
          variant="standard"
          type="text"
          label="Maa"
          error={errorMessages.country ? true : false}
          required={errorMessages.country ? true : false}
          name="country"
          value={customer.country}
          onChange={handleChange}
        />
        <TextField
          variant="standard"
          type="text"
          error={errorMessages.vatNumber ? true : false}
          required={errorMessages.vatNumber ? true : false}
          label="ALV-tunnus"
          name="vatNumber"
          value={customer.vatNumber}
          onChange={handleChange}
        />
        <div className="flex justify-center items-center hover:scale-105 ease-in-out duration-300 p-4">
          <Button disabled={Loading} variant="contained" sx={{ borderRadius: 6, width: '50%', padding: '10px' }} type="submit">
            Lisää uusi asiakas
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NewCustomer;
