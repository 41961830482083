import axios from 'axios';

/* const API_URL = process.env.REACT_APP_API_URL;
const PORT = process.env.PORT || 3001; */

export async function sendCustomer({ customer, currentUser, role }: { customer: any; currentUser: any; role?: string }) {
  //const customerURL = "http://" + API_URL + ":" + PORT + `/newCustomer`;
  const token = await currentUser.getIdToken();
  const data = { ...customer, role };
  const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/newCustomer', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.CustomerID;
}
