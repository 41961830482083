import { steps } from './content';
import { Typography } from '@mui/material';

export const GettingStarted = () => {
  return (
    <>
      {steps.map((step, index) => (
        <div key={index} className="p-4 justify-start px-10 items-center text-center gap-3 flex flex-col w-full py-10">
          <div className="items-center justify-center border border-greyBorder  w-12 h-12 my-2 rounded-lg flex">
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '1.5rem',
                fontWeight: 600,
                color: '#5c5c5c',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              {index + 1}
            </Typography>
          </div>
          <Typography
            sx={{
              color: '#333333',
              fontFamily: 'Poppins',
              fontWeight: 600,
              fontSize: '0.875rem',
            }}
            variant="h6"
          >
            {step.title.toUpperCase()}
          </Typography>
          <Typography
            sx={{
              color: '#666666',
              lineHeight: '1.3',
              fontweight: 600,
              fontFamily: 'open-sans',
              fontSize: '0.875rem',
            }}
            variant="body2"
          >
            {step.description}
          </Typography>
        </div>
      ))}
    </>
  );
};
