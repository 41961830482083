import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { Users, BadgeCheck, Video } from 'lucide-react';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      width: '100%',
      transition: 'background-image 0.3s ease-in-out, width 0.3s ease-in-out',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      width: '100%',
      transition: 'background-image 0.3s ease-in-out, width 0.3s ease-in-out',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
    width: 0,
    transition: 'width 0.3s ease-in-out',
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'background-image 0.3s ease-in-out, transform 0.3s ease-in-out',
  transform: 'scale(1)',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    transform: 'scale(1.2)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <Video />,
    4: <Users />,
    5: <BadgeCheck />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = [
  'Aseta kohteen tiedot',
  'Lisää yhteyshenkilö',
  'Lisää kamerat',
  'Aseta etäkäyttäjät',
  'Tarkista tiedot',
];

export default function CustomizedSteppers({ activeStep = 0 }) {
  return (
    <Stack className="mb-8 mt-10" sx={{ width: '100%', zIndex: 1 }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              sx={{
                '.MuiStepLabel-label': {
                  color: '#5c5c5c',

                  fontWeight: 500,
                  fontFamily: 'poppins',
                  letterSpacing: '0.001px',
                  lineHeight: '1.2',
                },
                '.MuiStepLabel-label.Mui-active': {
                  color: '#e9e9e9',
                  fontWeight: 500,
                  fontFamily: 'poppins',
                  letterSpacing: '0.001px',
                  lineHeight: '1.2',
                },
                '.MuiStepLabel-label.Mui-completed': {
                  color: '#00308F',
                  fontWeight: 500,
                  fontFamily: 'poppins',
                  letterSpacing: '0.001px',
                  lineHeight: '1.2',
                },
              }}
              StepIconComponent={ColorlibStepIcon}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
