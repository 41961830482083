import React, { useState, useEffect, useRef } from 'react';
import Cloud from './cloudicon';
import Line from './line';
import PCuser from './pcuser';
import PhoneUser from './phoneuser';
import Software from './software';
import Network from './network';
import Server from './server';
import Firewall from './firewall-svgrepo-com';
import Tooltip from '@mui/material/Tooltip';
import { Divider } from '@mui/material';

interface RenderIconProps {
  position: Position;
  id: string;
  children: React.ReactNode;
  width: string;
}

const RenderIcon: React.FC<RenderIconProps> = ({ position, id, children, width }) => {
  return (
    <div
      id={id}
      style={{
        position: 'absolute',
        width: width,
        top: position.top,
        left: position.left,
        zIndex: 5,
      }}
    >
      {children}
    </div>
  );
};

interface Position {
  top: string;
  left: string;
}

interface ElementPosition {
  x: number;
  y: number;
}

interface Positions {
  cloud: ElementPosition;
  laptop: ElementPosition;
  letterD: ElementPosition;
  phone: ElementPosition;
  fixedLetterC: ElementPosition;
  letterC: DOMRect | null;
  software: ElementPosition;
  network: ElementPosition;
  firewall: ElementPosition;
}

const getCenterOfElement = (element: HTMLElement | null): ElementPosition => {
  if (!element) return { x: 0, y: 0 };
  const rect = element.getBoundingClientRect();

  return { x: rect.left + rect.width / 2, y: rect.top + rect.height / 2 };
};

const Diagram = () => {
  const containerRef = useRef<HTMLDivElement | null>(null); // Ref to the parent container

  const [positions, setPositions] = useState<Positions>({
    cloud: { x: 0, y: 0 },
    laptop: { x: 0, y: 0 },
    letterD: { x: 0, y: 0 },
    phone: { x: 0, y: 0 },
    fixedLetterC: { x: 0, y: 0 },
    letterC: null,
    software: { x: 0, y: 0 },
    network: { x: 0, y: 0 },
    firewall: { x: 0, y: 0 },
  });

  useEffect(() => {
    const elementsRef = {
      cloud: document.getElementById('defs28'),
      letterD: document.getElementById('path2'),
      laptop: document.getElementById('laptop'),
      tunnelToCloud: document.getElementById('path4152-7-1-1'),
      phone: document.getElementById('phone'),
      fixedLetterC: document.getElementById('path6'),
      letterC: document.getElementById('path6'),
      software: document.getElementById('software'),
      wanPort: document.getElementById('path408'),
      vpn: document.getElementById('path3834-7-8'),
      firewall: document.getElementById('firewall'),
    };

    const container = containerRef.current;
    if (!container) return;

    const containerRect = container.getBoundingClientRect();

    // Adjust the positions to be relative to the parent container
    const adjustPosition = (pos: { x: number; y: number }) => ({
      x: pos.x - containerRect.left,
      y: pos.y - containerRect.top,
    });

    setPositions({
      cloud: adjustPosition(getCenterOfElement(elementsRef.cloud)),
      letterD: adjustPosition(getCenterOfElement(elementsRef.letterD)),
      laptop: adjustPosition(getCenterOfElement(elementsRef.laptop)),
      phone: adjustPosition(getCenterOfElement(elementsRef.phone)),
      fixedLetterC: adjustPosition(getCenterOfElement(elementsRef.letterC)),
      letterC: elementsRef.letterC ? elementsRef.letterC.getBoundingClientRect() : null,
      software: adjustPosition(getCenterOfElement(elementsRef.software)),
      network: adjustPosition(getCenterOfElement(elementsRef.wanPort)),
      firewall: adjustPosition(getCenterOfElement(elementsRef.firewall)),
    });
  }, []);

  const elementsIsReady = Object.values(positions).every((pos) => pos);

  return (
    <div
      ref={containerRef}
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        backgroundColor: '#f0f0f0',
      }}
      className="rounded-3xl"
    >
      <RenderIcon id="laptop" position={{ top: '65%', left: '65%' }} width="15%">
        <Tooltip
          title={
            <div className="text-center">
              <p>Tietokone etäkatselu</p>
              <Divider
                sx={{
                  backgroundColor: 'white',
                  margin: '0.5rem 0',
                }}
              />
              <p className=" font-bold">Windows | MacOS | Linux</p>
            </div>
          }
        >
          <div>
            <PCuser />
          </div>
        </Tooltip>
      </RenderIcon>

      <RenderIcon id="phone" position={{ top: '70%', left: '45%' }} width="10%">
        <Tooltip
          title={
            <div className="text-center">
              <p>Mobiili etäkatselu</p>
              <Divider
                sx={{
                  backgroundColor: 'white',
                  margin: '0.5rem 0',
                }}
              />
              <p className=" font-bold">Android | iOS</p>
            </div>
          }
        >
          <div>
            <PhoneUser />
          </div>
        </Tooltip>
      </RenderIcon>
      <RenderIcon id="network" position={{ top: '10%', left: '10%' }} width="20%">
        <Tooltip
          title={
            <div className="text-center">
              <p className="">Suojattu kameraverkko</p>
              <Divider
                sx={{
                  backgroundColor: 'white',
                  margin: '0.5rem 0',
                }}
              />
              <p className=" font-bold">Jopa 12 kameraa</p>
              <p className=" font-bold">Tallennusaika 30 päivää</p>
              <p className=" font-bold">Ethernet | 4G - tuki</p>
              <p className=" font-bold">PTZ-yhteensopiva</p>
            </div>
          }
        >
          <div>
            <Network />
          </div>
        </Tooltip>
      </RenderIcon>
      <RenderIcon id="software" position={{ top: '65%', left: '15%' }} width="15%">
        <Tooltip
          title={
            <div className="text-center">
              <p>Hallintaverkko asentajayritykselle </p>
              <Divider
                sx={{
                  backgroundColor: 'white',
                  margin: '0.5rem 0',
                }}
              />
              <p className=" font-bold">Käyttäjä- ja laitehallinta</p>
              <p className=" font-bold">Reaaliaikainen vianseuranta</p>
              <p className=" font-bold">GDPR-yhteensopiva</p>
            </div>
          }
        >
          <div>
            <Software />
          </div>
        </Tooltip>
      </RenderIcon>
      <RenderIcon id="cloud" position={{ top: '25%', left: '35%' }} width="35%">
        <Cloud position={positions.letterC} />
      </RenderIcon>
      <RenderIcon id="firewall" position={{ top: '5%', left: '65%' }} width="10%">
        <Tooltip
          title={
            <div className="text-center">
              <p>Turvalliset yhteydet VPN-Verkolla</p>
              <Divider
                sx={{
                  backgroundColor: 'white',
                  margin: '0.5rem 0',
                }}
              />
              <p className=" font-bold">SHA256 | AES256 | 3072Bit</p>
            </div>
          }
        >
          <div>
            <Firewall />
          </div>
        </Tooltip>
      </RenderIcon>

      <Server position={positions.fixedLetterC} />

      {elementsIsReady && (
        <>
          <Line from={positions.fixedLetterC} to={positions.software} bottom />
          <Line from={positions.fixedLetterC} to={positions.laptop} bottom />
          <Line from={positions.firewall} to={positions.letterD} left />
          {positions.letterC && <Line from={positions.fixedLetterC} to={positions.phone} />}
          {positions.firewall && <Line from={positions.firewall} to={positions.network} />}
        </>
      )}
    </div>
  );
};

export default Diagram;
