// Cod e to create a confirmation modal using mui
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, message }: { isOpen: boolean; onClose: () => void; onConfirm: () => void; title: string; message: string }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            fontFamily: 'open-sans',
            fontSize: { xs: '0.8rem', sm: '0.8rem', md: '1rem ' },
            fontWeight: 500,
            textAlign: 'center',
          }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Peru</Button>
        <Button onClick={onConfirm} color="secondary">
          Kyllä, olen varma
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
