import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import React from 'react';
import { Tooltip, Typography } from '@mui/material';

const DownloadGDPR = ({ gdprUrl, mediaSize }: { gdprUrl: string; mediaSize: any }) => {
  if (!gdprUrl) return null;
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = gdprUrl;
    link.download = 'download'; // or extract filename from imageurl
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const sxCell = {
    fontWeight: 500,
    fontFamily: 'open-sans',
    letterSpacing: -0.3,
    fontSize: { xs: '0.8rem', sm: '0.8rem', md: '1rem ' },
    color: '#5c5c5c',
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <Tooltip title="Lataa rekisteriseloste">
        <button onClick={handleDownload} className="download-button gap-1 flex flex-wrap  hover:scale-110 ease-in-out duration-300 hover:underline">
          {(mediaSize.lg || mediaSize.xl) && <PictureAsPdfIcon sx={{ color: '#43687e' }} />}

          <Typography sx={sxCell} className=" hover:underline">
            Rekisteriseloste
          </Typography>
        </button>
      </Tooltip>
    </div>
  );
};

export default DownloadGDPR;
