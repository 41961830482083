const Firewall = () => {
  return (
    <svg id="firewall" width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" version="1.1">
      <defs>
        <radialGradient id="RG1" cx="50%" cy="50%" fx="63%" fy="60%" r="60%">
          <stop style={{ stopColor: 'rgb(170,170,170)', stopOpacity: 1 }} offset="0%" />
          <stop style={{ stopColor: 'rgb(0,0,0)', stopOpacity: 1 }} offset="100%" />
        </radialGradient>
        <linearGradient id="flame" x1="0%" y1="90%" x2="0%" y2="60%">
          <stop style={{ stopColor: 'rgb(202,59,0)', stopOpacity: 1 }} offset="0%" />
          <stop style={{ stopColor: 'rgb(255,164,0)', stopOpacity: 1 }} offset="100%" />
        </linearGradient>
        <radialGradient id="RG_globe" cx="50%" cy="50%" fx="50%" fy="32%" r="28%">
          <stop style={{ stopColor: 'rgb(103,155,203)', stopOpacity: 1 }} offset="30%" />
          <stop style={{ stopColor: 'rgb(18,49,65)', stopOpacity: 1 }} offset="100%" />
        </radialGradient>
      </defs>

      <ellipse cx="35" cy="34" rx="32" ry="30" style={{ strokeWidth: 4, stroke: '#dddddd', fill: 'none' }} />
      <ellipse cx="35" cy="34" rx="32" ry="30" style={{ fill: 'url(#RG_globe)', fillOpacity: 1, fillRule: 'nonzero' }} />
      <g style={{ fill: 'none', stroke: '#aaaaaa', strokeWidth: '1.5px', strokeLinecap: 'butt' }}>
        <path d="M 36,64 C 22,56 19,46 19,34 19,22 26,10 36,4 l 0,60 C 36,64 54,55 54,34 54,13 36,4 36,4" />
        <path d="m 4,34 63,0 0,0" />
        <path d="m 13,15 c 0,0 12,7 23,7 13,0 23,-7 23,-7" />
        <path d="m 13,54 c 0,0 9,-7 23,-7 16,0 23,7 23,7" />
      </g>
      <ellipse cx="35" cy="34" rx="32" ry="30" style={{ strokeWidth: 3, stroke: '#123141', fill: 'none' }} />

      <path style={{ fill: 'none', stroke: '#eeeeee', strokeWidth: 5 }} d="m 35,67 c 0,0 -3,-9 -3,-42 19,8 27,-1 32,-8" />
      <path
        style={{ fill: 'url(#RG1)', fillOpacity: 1, fillRule: 'nonzero', stroke: '#333333', strokeWidth: 4 }}
        d="M 64,19 C 59,25 52,34 33,26 34,66 33,82 64,96 96,82 97,69 96,26 77,34 68,25 64,19 z"
      />

      <path
        style={{ fill: 'url(#flame)', stroke: '#B03104', strokeWidth: 2 }}
        d="m 38,73 c 7,-6 8,-10 8,-20 0,-4 -1,-12 5,0 5,12 -1,13 5,23 7,-6 4,-11 5,-18 3,13 9,3 7,22 5,-7 6,-24 12,-33 0,10 3,14 3,21 5,-7 4,-13 7,-14 1,14 6,1 5,10 C 90,83 80,89 64,96 46,88 41,80 38,73 z"
      />
      <path style={{ fill: 'none', stroke: '#333333', strokeWidth: 4 }} d="M 64,19 C 59,25 52,34 33,26 34,66 33,82 64,96 96,82 97,69 96,26 77,34 68,25 64,19 z" />
    </svg>
  );
};

export default Firewall;
