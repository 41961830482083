import React from 'react';

const ServerTable = ({ handleLicenseUpdate, servers }: any) => {
  return (
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" className="py-3 px-6">
            ID
          </th>
          <th scope="col" className="py-3 px-6">
            Name
          </th>
          <th scope="col" className="py-3 px-6">
            IP
          </th>
          <th scope="col" className="py-3 px-6">
            Host
          </th>
          <th scope="col" className="py-3 px-6">
            Lisenssit
          </th>
          <th scope="col" className="py-3 px-6">
            Port
          </th>

          <th scope="col" className="py-3 px-6">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {servers.map((server: any) => (
          <tr
            key={server.id}
            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
          >
            <td className="py-4 px-6">{server.id}</td>
            <td className="py-4 px-6">{server.name}</td>
            <td className="py-4 px-6">{server.ip}</td>
            <td className="py-4 px-6">{server.host}</td>
            <td className="py-4 px-6">{server.licenseCount}</td>
            <td className="py-4 px-6">{server.port}</td>
            <td className="py-4 px-6">
              <button
                className="font-semibold text-blue-600 dark:text-blue-500 hover:underline"
                onClick={() => {
                  handleLicenseUpdate(server);
                }}
              >
                Päivitä lisenssimäärä
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ServerTable;
