import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import './modal.css';
type TooltipProps = {
  title: string;
  style: TooltipStyle;
  phase?: number;
  placement?: 'top' | 'bottom' | 'left' | 'right';
};
type TooltipStyle = {
  top: string;
  left: string;
  width: string;
  height: string;
};

type ImageAnimationProps = {
  top: string;
  left: string;
  width: string;
  height: string;
};
// Aseta app-elementti, jotta React Modal tietää, mihin kiinnittää modaalin
Modal.setAppElement('#root');
type CurrentImage = {
  currentImageIndex: number | null;
  setCurrentImageIndex: any;
};
type RenderImageProps = {
  src: string;
  alt: string;
  text?: string;
  className?: any;
  imageAnimationProps?: ImageAnimationProps;
  helperText?: string;
  credentials?: boolean;
  tooltip?: TooltipProps[];
  phases?: boolean;
};
const ToolTipPhases = ({ tooltips, currentImage }: { tooltips: TooltipProps[]; currentImage: CurrentImage }) => {
  const [currentPhase, setCurrentPhase] = useState<number | null>(0);
  const [isPaused, setIsPaused] = useState(false);

  const sortedTips = tooltips.sort((a, b) => (a.phase! > b.phase! ? 1 : -1)).filter((tooltip) => tooltip.phase !== undefined);

  useEffect(() => {
    if (!isPaused && currentPhase !== null) {
      const currentTextLength = sortedTips[currentPhase].title.length;
      const isLastPhase = currentPhase === sortedTips.length - 1;
      const intervalDuration = currentTextLength * 200 + (isLastPhase ? 1000 : 0);

      const interval = setInterval(() => {
        setCurrentPhase((prevPhase) => (prevPhase! + 1) % sortedTips.length);
      }, intervalDuration);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaused, sortedTips.length, currentPhase]);

  // Nollaa currentPhase, kun currentImage muuttuu, ja aloita animaatio alusta viiveen jälkeen
  useEffect(() => {
    setCurrentPhase(null); // Nollataan tooltipit
    const resetTimer = setTimeout(() => {
      setCurrentPhase(0); // Aloita alusta lyhyen viiveen jälkeen
    }, 500); // 500 ms viive nollaukselle

    return () => clearTimeout(resetTimer);
  }, [currentImage]);

  return (
    <>
      {sortedTips.map((tooltip, index) => {
        const left = parseInt(tooltip.style.left.replace('%', '')) - 2 + '%';
        const top = parseInt(tooltip.style.top.replace('%', '')) - 4 + '%';

        return (
          <div
            key={index}
            className={`absolute transition-opacity duration-500 ${index === currentPhase ? 'opacity-100' : 'opacity-0'}`}
            style={{
              top: top,
              left: left,
              position: 'absolute',
            }}
          >
            <div
              className={`relative flex flex-row items-center text-white bg-blue-500 rounded-xl tooltip-content ${tooltip.placement || 'top'}`}
              onMouseEnter={() => setIsPaused(true)}
              onMouseLeave={() => setIsPaused(false)}
            >
              <span className="tooltip-text bg-blue-500 text-white rounded text-sm items-center justify-center shadow-lg px-1">{`${tooltip.phase}.`}</span>
              {(isPaused || currentPhase === index) && (
                <div className="animated-text-container items-center text-sm">
                  {[...tooltip.title].map((char, i) => (
                    <span key={i} className="animated-text font-poppins" style={{ '--char-index': i } as React.CSSProperties}>
                      {char}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

const RenderImage = ({ imageProps, currentImage, initialIndex }: { imageProps: RenderImageProps[]; currentImage: CurrentImage; initialIndex: number }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    currentImage.setCurrentImageIndex(initialIndex); // Avaa modal ja aseta tila
    setIsOpen(true);
  };

  const closeModal = () => {
    currentImage.setCurrentImageIndex(null); // Tyhjennä tila modaalin sulkemisen yhteydessä
    setIsOpen(false);
  };

  const currentImageData = currentImage.currentImageIndex !== null ? imageProps[currentImage.currentImageIndex] : null;

  return (
    <>
      <div onClick={openModal} className="justify-evenly flex flex-col items-center cursor-pointer relative w-1/3 gap-3">
        <div className="flex justify-center relative ">
          <img src={imageProps[initialIndex].src} alt={imageProps[initialIndex].alt} className="object-fit max-h-56" />
          <Typography variant="caption" fontFamily={'poppins'} className="absolute top-[50%] bg-blue-gray-500 bg-opacity-70 rounded-xl p-2 text-white hover:scale-110 ease-in-out duration-300">
            AVAA
          </Typography>
        </div>
        <p className="text-sm text-pretty text-center">{imageProps[initialIndex].text}</p>
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="flex justify-center items-center max-w-[80%] p-4 bg-white rounded-lg shadow-lg outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      >
        <button onClick={closeModal} className="absolute top-20 right-20 text-gray-600 hover:text-gray-800 text-xl font-bold">
          &times;
        </button>
        {currentImageData && (
          <div className="contentBox relative">
            {currentImageData.tooltip &&
              currentImageData.tooltip.map((tooltip, index) => {
                return (
                  <Tooltip key={index} title={tooltip.phase ? '' : tooltip.title} arrow placement={tooltip.placement} style={tooltip.style}>
                    <div className="absolute border border-blue-500 rounded-full " />
                  </Tooltip>
                );
              })}
            {currentImageData.phases && currentImageData.tooltip && <ToolTipPhases tooltips={currentImageData.tooltip} currentImage={currentImage} />}

            <img src={currentImageData.src} alt={currentImageData.alt} className="w-auto h-auto max-w-full max-h-[80vh] object-contain" />

            {currentImageData.imageAnimationProps && currentImageData.helperText && (
              <div className="absolute text-white animate-pulse" style={handleHelperText({ imageAnimationProps: currentImageData.imageAnimationProps })}>
                <span className="bg-red-500 px-2 py-1 rounded text-nowrap">{currentImageData.helperText}</span>
              </div>
            )}
            <div className="absolute border border-blue-500 rounded-full animate-ping" style={currentImageData.imageAnimationProps} />
            {currentImageData.credentials && <Credentials />}

            <div className="fixed top-1/2 w-full">
              {currentImage.currentImageIndex !== null && currentImage.currentImageIndex !== 0 && (
                <div className="absolute left-[-2rem] ">
                  <button
                    onClick={() => {
                      if (currentImage.currentImageIndex! > 0) {
                        currentImage.setCurrentImageIndex(currentImage.currentImageIndex! - 1);
                      }
                    }}
                    className="bg-blue-500 border-blue-gray-500 border-4 shadow-lg text-white p-2 rounded-full w-12 hover:bg-blue-600 hover:scale-110 ease-in-out duration-300"
                  >
                    {'<'}
                  </button>
                </div>
              )}

              {currentImage.currentImageIndex !== null && currentImage.currentImageIndex + 1 !== imageProps.length && (
                <div className="absolute right-[-2rem] ">
                  <button
                    onClick={() => {
                      if (currentImage.currentImageIndex! < imageProps.length - 1) {
                        currentImage.setCurrentImageIndex(currentImage.currentImageIndex! + 1);
                      }
                    }}
                    className="bg-blue-500 border-blue-gray-500 border-4 shadow-lg text-white p-2 rounded-full w-12 hover:bg-blue-600 hover:scale-110 ease-in-out duration-300"
                  >
                    {'>'}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default RenderImage;

const Credentials = () => {
  return (
    <>
      <Typography className="absolute text-blue-500 animate-pulse text-nowrap" style={{ top: '10%', left: '45%', fontFamily: 'sans', fontSize: 14 }}>
        Aseta haluamasi nimi
      </Typography>
      <Typography className="absolute text-blue-500 animate-pulse text-nowrap" style={{ top: '15%', left: '45%', fontFamily: 'sans', fontSize: 14 }}>
        Aseta palvelimen osoite
      </Typography>
      <Typography className="absolute text-blue-500 animate-pulse text-nowrap" style={{ top: '20%', left: '45%', fontFamily: 'sans', fontSize: 14 }}>
        Aseta portti
      </Typography>
      <Typography className="absolute text-blue-500 animate-pulse text-nowrap" style={{ top: '25%', left: '45%', fontFamily: 'sans', fontSize: 14 }}>
        Aseta käyttäjätunnus
      </Typography>
      <Typography className="absolute text-blue-500 animate-pulse text-nowrap" style={{ top: '30%', left: '45%', fontFamily: 'sans', fontSize: 14 }}>
        Aseta salasana
      </Typography>
      <div className="absolute border border-blue-500 rounded-full animate-ping duration-300" style={{ top: '44.5%', left: '88%', height: 40, width: 40 }} />
      <Typography className="absolute animate-pulse text-blue-500 text-nowrap" style={{ top: '46%', left: '40%', fontFamily: 'sans', fontSize: 14 }}>
        Suositellaan salattua yhteyttä
      </Typography>
    </>
  );
};
const handleHelperText = ({ imageAnimationProps }: { imageAnimationProps: ImageAnimationProps }) => {
  let top = parseInt(imageAnimationProps.top.replace('%', ''));
  let left = parseInt(imageAnimationProps.left.replace('%', ''));

  // Muutetaan top-arvo
  if (top < 50) {
    top += 5;
  } else {
    top -= 5;
  }

  // Valitaan palautettavaksi joko left tai right
  if (left < 50) {
    // Jos left on alle 50, lisätään 5%
    left -= 5;
    return {
      top: `${top}%`,
      left: `${left}%`,
    };
  } else {
    // Jos left on yli 50, vähennetään 15%
    const right = left - 65;
    return {
      top: `${top}%`,
      right: `${right}%`,
    };
  }
};
