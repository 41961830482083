import { useAuth } from '../../context/authContext';
import { Introduction } from './introduction';
import { SecondIntroduction } from './secondIntroduction';
import { Properties } from './properties';
import { NeedToStart } from './needToStart';
import { Configurations } from './easyConfiguration';
const Home = () => {
  const { currentUser } = useAuth();

  return (
    <div className="w-full ">
      <Introduction currentUser={currentUser} />
      <SecondIntroduction />
      <Properties />
      <NeedToStart />
      <Configurations currentUser={currentUser} />
    </div>
  );
};

export default Home;
