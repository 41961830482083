import { useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import Tietokone from './setup/pc';
import Mobiili from './setup/mobile';

const Guides = ({ searchParams }: { searchParams: string[] | null }) => {
  const [value, setValue] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [animationDirection, setAnimationDirection] = useState('');

  useEffect(() => {
    if (searchParams && searchParams.length > 0) {
      if (searchParams.includes('mobiili')) {
        setValue(1);
      }
      if (searchParams.includes('tietokone')) {
        setValue(0);
      }
    }
  }, [searchParams]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue !== value) {
      // Määritellään animaation suunta
      if (newValue > value) {
        setAnimationDirection('left'); // Uusi arvo on suurempi
      } else {
        setAnimationDirection('right'); // Uusi arvo on pienempi
      }
      setIsAnimating(true); // Käynnistetään animaatio
      setTimeout(() => {
        setValue(newValue); // Päivitetään uusi arvo
        setIsAnimating(false); // Lopetetaan animaatio
      }, 500); // Animaation kesto
    }
  };

  const RenderContent = () => {
    switch (value) {
      case 0:
        return <Tietokone />;
      case 1:
        return <Mobiili />;
      default:
        return null;
    }
  };

  return (
    <div className="w-full h-[85vh] lg:h-[80vh] flex flex-col">
      <Tabs
        value={value}
        onChange={handleChange}
        centered
        className="bg-transparent text-white rounded-t-xl mb-4 drop-shadow-2xl"
        textColor="inherit"
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: 'white',
          },
        }}
      >
        <Tab label="Tietokone" sx={{ color: value !== 0 ? '#52525b' : 'white', fontFamily: 'poppins' }} />
        <Tab label="Mobiili" sx={{ color: value !== 1 ? '#52525b' : 'white', fontFamily: 'poppins' }} />
      </Tabs>
      <div className={`relative overflow scrollbar-hide flex-1`} style={{ position: 'relative' }}>
        <div
          className={` inset-0 flex items-center justify-center transition-all duration-500 ease-in-out`}
          style={{
            opacity: isAnimating ? 0 : 1,
            transform: isAnimating ? (animationDirection === 'left' ? 'translateX(-100%)' : 'translateX(100%)') : 'translateX(0)',
          }}
        >
          <div className="bg-white rounded-xl p-4 shadow-lg mb-6 ">
            <RenderContent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guides;
