import { Typography } from '@mui/material';

export default function About() {
  const textStyles = {
    color: '#666666',
    lineHeight: '1.7',
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    textAlign: 'justify',
    marginBottom: '1.5rem',
  };

  const titleStyles = {
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#333333',
    textAlign: 'center',
    marginBottom: '1rem',
  };

  const sectionTitleStyles = {
    fontFamily: 'Poppins',
    fontWeight: 600,
    color: '#444444',
    textAlign: 'left',
    marginTop: '2rem',
    marginBottom: '1rem',
  };

  return (
    <div className="flex w-full h-full max-w-full ">
      <div className="w-full bg-gray-100  justify-center flex mx-auto p-8 rounded-lg shadow-md  pb-10 max-w-full overflow-y-auto  scrollbar ">
        <div className="flex flex-col items-center max-w-4xl w-full  ">
          {/* Otsikko */}
          <Typography variant="h4" sx={titleStyles}>
            Keitä me olemme?
          </Typography>

          {/* Taustamme ja kehityksemme */}
          <Typography variant="h5" sx={sectionTitleStyles}>
            Taustamme ja kehityksemme
          </Typography>
          <Typography variant="body1" sx={textStyles}>
            Olemme Pirkanmaan turva-alan ammattilaisten perustama yritys, jonka päätavoitteena on tarjota korkealaatuista ja luotettavaa kameravalvonnan tallennusratkaisua pilvipohjaisella
            teknologialla. Yrityksemme syntyi turvallisuusalan tarpeesta kehittää helppokäyttöinen ja tehokas valvontaohjelmisto, joka vastaa sekä ammattilaisten että markkinoiden
            kustannustehokkuusvaatimuksiin. Kokemuksemme kameravalvonnasta ulottuu vuosien taakse analogisista kameroista uusimpiin tekoälyllä varustettuihin IP-kameroihin, joten olemme nähneet alan
            kehityksen läheltä.
          </Typography>

          <Typography variant="body1" sx={textStyles}>
            Ajatus pilvipohjaisesta tallennusratkaisusta syntyi jo vuonna 2014, kun asiakkaamme tarvitsi turvallisen ja helposti saatavilla olevan tavan tallenteiden säilyttämiseen. Perinteiset
            tallennusratkaisut ovat usein kalliita ja vaativat jatkuvaa valvontaa sekä huoltoa, joten halusimme tarjota asiakkaillemme paremman vaihtoehdon. Kymmenen vuotta myöhemmin, vuonna 2024,
            julkaisimme pilvipohjaisen kameravalvonnan hallintaohjelmiston ObsCloudin, joka vastaa tämän päivän vaatimuksia ja on helppo ottaa käyttöön.
          </Typography>

          {/* Pilvipohjainen ratkaisumme */}
          <Typography variant="h5" sx={sectionTitleStyles}>
            Pilvipohjainen ratkaisumme
          </Typography>
          <Typography variant="body1" sx={textStyles}>
            Pilvipohjainen tallennusratkaisumme on suunniteltu erityisesti valvontayrityksille ja turvallisuusalan ammattilaisille, jotka tarvitsevat luotettavaa ja helppokäyttöistä
            tallennusratkaisua, jonka päälle on helppo rakentaa muita turvallisuuspalveluita. Pilvipohjainen järjestelmämme mahdollistaa turvallisen ja keskeytymättömän videotallennuksen, joka on
            helposti saatavilla ajasta ja paikasta riippumatta.
          </Typography>

          {/* Tietoturva ja luotettavuus */}
          <Typography variant="h5" sx={sectionTitleStyles}>
            Tietoturva ja luotettavuus etusijalla
          </Typography>
          <Typography variant="body1" sx={textStyles}>
            Ymmärrämme, että luotettavuus, tietoturva ja käytön helppous ovat keskeisiä vaatimuksia kaikille, jotka työskentelevät turvallisuusalan parissa. Siksi tarjoamme räätälöityjä ratkaisuja,
            joiden avulla asiakkaamme voivat hallita valvontakamerajärjestelmiään tehokkaasti ja turvallisesti.
          </Typography>

          {/* Tavoitteenamme auttaa asiakkaitamme menestymään */}
          <Typography variant="h5" sx={sectionTitleStyles}>
            Tavoitteenamme auttaa asiakkaitamme menestymään
          </Typography>
          <Typography variant="body1" sx={{ ...textStyles, paddingBottom: 20 }}>
            Tavoitteenamme on tuoda alan uusinta teknologiaa kaikkien turva-alan ammattilaisten ulottuville ja auttaa yrityksiä suojelemaan omaisuuttaan ja henkilökuntaansa paremmin. Asiakaskeskeinen
            lähestymistapamme takaa sen, että pystymme jatkuvasti kehittämään palveluitamme vastaamaan muuttuvia tarpeita.
          </Typography>
        </div>
      </div>
    </div>
  );
}
