import React, { useState, useEffect } from 'react';
import './styles/loadingGear.css';
import { Settings } from 'lucide-react';
import { useCustomer } from '../context/customerContext';

const LoadingGear = ({ customState }: any) => {
  const { loadingText, state } = useCustomer() as any;
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 4 ? prevDots + '.' : ''));
    }, 500); // 500ms interval, voit muuttaa nopeutta tarpeen mukaan

    return () => clearInterval(interval);
  }, []);

  const noDots = state === 11 || state === 12;
  return (
    <div className="flex justify-center items-center w-full h-full ">
      <div className="flex flex-col justify-center items-center w-full">
        <Settings size={150} className="slow-spin text-white " />
        <div className="text-darkPurple font-poppins text-2xl font-bold text-center  justify-center flex items-start w-96 ">
          {customState ? customState.toUpperCase() : loadingText.toUpperCase()}
          {!noDots && dots}
        </div>
      </div>
    </div>
  );
};

export default LoadingGear;
