//import { Typography } from "@material-tailwind/react";
import { Typography } from '@mui/material';
const sx = {
  fontFamily: 'Poppins',
  fontSize: '1rem',
  fontWeight: 500,
  color: '#5c5c5c',
};
const TableRow = ({ name, location }: any) => (
  <tr className="border-b">
    <td className="py-2 px-3 text-left">
      <Typography sx={sx}>{name}</Typography>
    </td>
    <td className="py-2 px-3">
      <Typography
        sx={{
          fontFamily: 'open-sans',
          fontSize: '0.875rem',
          fontWeight: 500,
          color: '#5c5c5c',
        }}
      >
        {location}
      </Typography>
    </td>
  </tr>
);

export const CamerasTable = ({ customer, errors, showErrorIndicator }: any) => {
  return (
    <div className="mb-6">
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: '1.1rem',
          fontWeight: 600,
          color: '#5c5c5c',
        }}
      >
        Lisättävät kamerat
      </Typography>

      <table className="min-w-full table-auto">
        <tbody>
          {customer.cameras.map((camera: any, index: any) => (
            <tr key={index} className="border-b">
              <td className="py-2 px-3">
                <Typography sx={sx}>{index + 1}</Typography>
              </td>
              <td className="py-2 px-3">
                <Typography sx={sx}>{camera.name}</Typography>
              </td>
              <td className="py-2 px-3">
                <Typography sx={sx}>{camera.location}</Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const RemoteUsersTable = ({
  customer,
  errors,
  showErrorIndicator,
}: any) => {
  return (
    <div className="mb-6">
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: '1.1rem',
          fontWeight: 600,
          color: '#5c5c5c',
        }}
      >
        Käyttäjät
      </Typography>
      <table className="min-w-full table-auto">
        <tbody>
          {customer.remoteUsers.map((user: any, index: any) => (
            <tr key={index} className="border-b">
              <td className="py-2 px-3">{index + 1}</td>

              <td className="py-2 px-3">
                <Typography sx={sx}>{user.name}</Typography>
              </td>
              <td className="py-2 px-3">
                <Typography sx={sx}>{user.email}</Typography>
              </td>

              <td className="py-2 px-3">
                <Typography sx={sx}>{user.phone}</Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const UserInformationTable = ({
  customer,
  errors,
  showErrorIndicator,
}: any) => {
  return (
    <table className="min-w-full table-auto mb-6">
      <tbody>
        <TableRow name="Nimi" location={customer.basicInfo.name} />
        <TableRow name="Street" location={customer.basicInfo.street} />
        <TableRow name="City" location={customer.basicInfo.city} />
        <TableRow name="Postal code" location={customer.basicInfo.postalCode} />
      </tbody>
    </table>
  );
};

export const ContactInformationTable = ({ customer }: any) => {
  return (
    <table className="min-w-full table-auto mb-6 text-white">
      <tbody>
        <TableRow name="Yhteyshenkilö" location={customer.contactPerson.name} />
        <TableRow name="Sähköposti" location={customer.contactPerson.email} />
        <TableRow name="Puhelin" location={customer.contactPerson.phone} />
      </tbody>
    </table>
  );
};
