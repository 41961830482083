import React, { useState } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Typography, Select, MenuItem, SelectChangeEvent, Tooltip } from '@mui/material';
import type { MediaSize } from '../dashboard/collapsibleTable';

const rutModels = {
  x08: 'RUT X08',
  x09: 'RUT X09',
  m09: 'RUT M09',
  m08: 'RUT M08',
};

const sxCell = {
  fontWeight: 500,
  fontFamily: 'open-sans',
  letterSpacing: -0.3,
  fontSize: { xs: '0.8rem', sm: '0.8rem', md: '1rem ' },
  color: '#5c5c5c',
};

type Site = {
  x08: string;
  x09: string;
  m09: string;
  m08: string;
};

const DownloadRut = ({ imageurl, mediaSize }: { imageurl: Site; mediaSize: MediaSize }) => {
  // Initial state is empty to indicate no selection
  const [selectedModel, setSelectedModel] = useState<keyof Site | ''>('');

  // Handle model selection
  const handleModelChange = (event: SelectChangeEvent<keyof Site | ''>) => {
    setSelectedModel(event.target.value as keyof Site | '');
  };

  // Function to download the selected model
  const handleDownload = () => {
    const url = imageurl[selectedModel as keyof Site];
    if (url) {
      const link = document.createElement('a');
      link.href = url;
      link.download = `${selectedModel}.config`; // Use selected model as filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Download URL not available');
    }
  };

  return (
    <Tooltip title="Lataa reitittimen konfiguraatiotiedosto">
      <div className="flex flex-row items-center justify-center h-full px-2 ">
        {/* Select Dropdown */}
        <Select
          variant="standard"
          value={selectedModel}
          onChange={handleModelChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Select RUT model' }}
          sx={{ marginRight: '1rem', width: '130px' }} // Optional styling for spacing
        >
          {/* Placeholder option */}
          <MenuItem value="">
            <em>Valitse reititin</em>
          </MenuItem>
          {Object.entries(rutModels).map(([key, label]) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </Select>

        {/* Download Button */}

        <button
          onClick={handleDownload}
          className="download-button gap-3 flex flex-wrap hover:scale-110 ease-in-out duration-300 hover:underline"
          disabled={!selectedModel} // Disable if no model is selected
        >
          {selectedModel && (mediaSize.lg || mediaSize.xl) && <CloudDownloadIcon sx={{ color: '#43687e' }} />}
          <Typography sx={sxCell} className="text-sm hover:underline">
            {mediaSize.lg || mediaSize.xl ? (selectedModel ? `Lataa ${rutModels[selectedModel]}` : '') : selectedModel ? `${selectedModel.toUpperCase()} Lataa` : ''}
          </Typography>
        </button>
      </div>
    </Tooltip>
  );
};

export default DownloadRut;
