import React from 'react';

type Position = {
  x: number;
  y: number;
};

type Color = {
  from: string;
  to: string;
};

type Props = {
  from: Position;
  to: Position;
  color?: Color;
  zIndex?: number;
  left?: boolean;
  bottom?: boolean;
};

const Line = ({ from, to, color, zIndex = 1, left = false, bottom = false }: Props) => {
  // Calculate the control point for the Bézier curve
  const controlX = (from.x + to.x) / (left ? 1 : 2);
  const controlY = (from.y + to.y) / (bottom ? 0.85 : 2) - from.x / (bottom ? 1.1 : 6);

  return (
    <svg
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: zIndex,
      }}
    >
      <defs>
        <animate xlinkHref="#lineGradient" attributeName="x1" values="0;1;0" dur="4s" repeatCount="indefinite" />
        <animate xlinkHref="#lineGradient" attributeName="x2" values="1;0;1" dur="4s" repeatCount="indefinite" />
        <linearGradient id="lineGradient" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0%" stopColor={color?.from || 'violet'} />
          <stop offset="100%" stopColor={color?.to || 'skyblue'} />
        </linearGradient>
      </defs>
      <path d={`M${from.x},${from.y} Q${controlX},${controlY} ${to.x},${to.y}`} stroke="url(#lineGradient)" strokeWidth={6} fill="transparent" strokeDasharray="8" strokeDashoffset="1">
        <animate attributeName="stroke-dashoffset" from="30" to="90" dur="4s" repeatCount="indefinite" fill="freeze" />
      </path>
    </svg>
  );
};

export default Line;
