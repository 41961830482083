import { Outlet } from 'react-router-dom';
import TopBar from './components/navigationBar';
import backGroundImage from './assets/pictures/OBS-tausta.jpg';
import SpeedDialComponent from './components/speedDial/speedDial';
import Footer from './components/footer';

const Layout = () => {
  return (
    <main className="App h-screen flex flex-col">
      <div style={{ backgroundImage: `url(${backGroundImage})` }} className="flex bg-cover bg-center bg-no-repeat flex-col min-h-screen bg-blue-gray-400 ">
        <TopBar />

        <div className="flex flex-col w-full h-full items-center overflow-y-auto hidescrollbar">
          <Outlet />
        </div>
        <SpeedDialComponent />
        <Footer />
      </div>
    </main>
  );
};

export default Layout;
