import React, { useState } from 'react';
import { Checkbox } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useApi } from '../../../context/ApiCalls';
const PtzCheckBox = ({ camera }: { camera: any }) => {
  const [checked, setChecked] = useState(camera.ptz.enabled);
  const [loading, setLoading] = useState(false);
  const { updateCamera } = useApi();
  const data = {
    ptz: !checked,
    id: camera.id,
  };

  const handleCheck = async () => {
    const response = await updateCamera(data);
    if (response?.status === 200) {
      setChecked(!checked);
      setLoading(false);
    } else {
      setLoading(false);
    }
    /*   setTimeout(() => {
      setChecked(!checked);
      setLoading(false);
    }, 1000); */
  };

  return (
    <div className="flex h-10 justify-center items-center">
      {!loading ? (
        <Checkbox
          disabled={loading}
          checked={checked}
          onClick={() => {
            setLoading(true);
            handleCheck();
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ) : (
        <CircularProgress size={25} />
      )}
    </div>
  );
};

export default PtzCheckBox;
