// Date: 07/20/21
import React from 'react';
import { Typography } from '@mui/material';
import pilvi from '../../assets/pictures/OBS-pilvi.jpg';
import RegisterNow from '../../components/button/registerNow';
import { GettingStarted } from './gettingStarted';

export const Configurations = ({ currentUser }: { currentUser: any }) => {
  return (
    <div className="flex flex-wrap bg-white justify-center">
      <div className="lg:w-4/5 justify-center flex flex-wrap">
        <div className="w-full md:w-1/2 flex flex-col justify-center items-center p-4 text-center">
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '1.75rem',
              color: '#333333',
              fontWeight: 600,
            }}
            gutterBottom
            component="div"
          >
            Helppo käyttöönotto
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontFamily: 'open-sans',
              fontSize: '1rem',
              color: '#666666',
              lineHeight: '1.3',
            }}
            gutterBottom
            component="div"
          >
            OBSCloudin käyttöönotto on helppoa ja nopeaa. Luo tili ja aloita
            kameroiden hallinta heti.
          </Typography>
          <div className="h-1/2">
            <img
              src={pilvi}
              alt="Pilvi"
              className="w-full h-auto min-w-[56px] min-h-14 md:min-w-[124px] max-w-[525px] justify-center items-center p-4 md:p-11"
            />
          </div>
        </div>
        <div className="w-full md:w-1/2 grid grid-cols-2 gap-4">
          <GettingStarted />
        </div>
      </div>
      <div className="w-full justify-center flex items-center mb-20">
        {!currentUser && <RegisterNow text="ALOITA NYT JA REKISTERÖIDY" />}
      </div>
    </div>
  );
};
