import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-[#1b1b1b] text-white text-center py-4 sticky bottom-0 z-10">
      <Typography
        sx={{
          fontFamily: 'open-sans',
          fontSize: '0.8rem',
          fontWeight: 500,
          color: '#999999',
        }}
      >
        © 2024 OBSCloud. All rights reserved. |
        <Link
          to="/privacyPolicy"
          className="
           hover:text-blue-700 duration-300 hover:underline
          "
        >
          {' '}
          Yksityisyyskäytäntö
        </Link>
      </Typography>
    </footer>
  );
};

export default Footer;
