import React, { useState, useCallback, useMemo } from 'react';
import { Button, TextField } from '@mui/material';

interface ServerProps {
  name: string | undefined;
  ip: string | undefined;
  licenseCount: number | undefined;
  username: string | undefined;
  password: string | undefined;
  host: string | undefined;
  port: number | undefined;
}

interface NewServerProps {
  handleAddNewServer: (server: ServerProps) => void;
}

const NewServer = ({ handleAddNewServer }: NewServerProps) => {
  const [server, setServer] = useState<ServerProps>({
    name: '',
    ip: '',
    licenseCount: 0,
    username: '',
    password: '',
    host: '',
    port: 9191,
  });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setServer((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleAddNewServer(server);
  };

  // Use useMemo to prevent unnecessary re-creation of the input elements
  const inputs = useMemo(
    () => [
      { name: 'name', placeholder: 'Nimi', value: server.name, label: 'Nimi' },

      {
        name: 'ip',
        placeholder: 'IP-osoite',
        value: server.ip,
        label: 'IP-Osoite',
      },
      {
        name: 'port',
        placeholder: 'Portti',
        value: server.port,
        type: 'number',
        label: 'Portti',
      },
      {
        name: 'username',
        placeholder: 'API-käyttäjä',
        value: server.username,
        label: 'Käyttäjätunnus',
      },
      {
        name: 'password',
        placeholder: 'API-salasana',
        value: server.password,
        type: 'password',
        label: 'Salasana',
      },
      {
        name: 'host',
        placeholder: 'esim. remote.ltpoy.fi',
        value: server.host,
        label: 'Etäpalvelimen osoite',
      },
      {
        name: 'licenseCount',
        placeholder: 'Anna lisenssien lukumäärä',
        value: server.licenseCount,
        type: 'number',
        label: 'Kameralisenssit',
      },
    ],
    [server]
  );

  const Input = useCallback(
    ({
      name,
      placeholder,
      value,
      type = 'text',
      label,
    }: {
      name: string;
      placeholder: string;
      value: string | number | undefined;
      type?: string;
      label?: string | undefined;
    }) => {
      return (
        <TextField
          key={name}
          variant="standard"
          placeholder={placeholder ? placeholder : undefined}
          label={label ? label : undefined}
          sx={{ margin: '0.5rem' }}
          className="w-2/3 p-2 m-2 rounded-md border-2 border-blue-gray-300"
          type={type}
          name={name}
          required
          value={value}
          onChange={handleChange}
          autoComplete="off"
        />
      );
    },
    [handleChange]
  );

  return (
    <div className="flex w-full h-full justify-center items-center flex-col">
      <form
        className="flex w-full h-full justify-center items-center flex-col"
        onSubmit={handleSubmit}
      >
        {inputs.map((input) => (
          <Input key={input.name} {...input} />
        ))}
        <Button
          className="w-2/3 p-2 m-2 rounded-md border-2 border-blue-gray-300"
          type="submit"
        >
          Lisää palvelin
        </Button>
      </form>
    </div>
  );
};

export default NewServer;
