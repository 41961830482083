import React from 'react';

const TableHeader = () => {
  return (
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        <th scope="col" className="py-3 px-6">
          Asiakasnumero
        </th>
        <th scope="col" className="py-3 px-6">
          Yritys
        </th>
        <th scope="col" className="py-3 px-6">
          Osoite
        </th>
        <th scope="col" className="py-3 px-6">
          Sähköposti
        </th>
        <th scope="col" className="py-3 px-6">
          Puhelin
        </th>
        <th scope="col" className="py-3 px-6">
          Y-Tunnus
        </th>
        <th scope="col" className="py-3 px-6">
          Toiminnot
        </th>
      </tr>
    </thead>
  );
};

type User = {
  accountID: string;
  name: string;
  address: string;
  email: string;
  phone: string;
  country: string;
  vatNumber: string;
  createdAt: string;
  updatedAt: string;
  billingCustomerId: number;
  id: number;
};

type Client = {
  id: number;
  accountID: string;
  name: string;
  address: string;
  email: string;
  phone: string;
  vatNumber: string;
  country: string;
  createdAt: string;
  updatedAt: string;
  users: User[];
};

const Table = ({ handleInviteReseller, clients }: { handleInviteReseller: (client: Client) => void; clients: Client[] }) => {
  const [showUsers, setShowUsers] = React.useState<number | null>(null);

  const toggleShowUsers = (clientId: number) => {
    if (showUsers === clientId) {
      setShowUsers(null); // Piilota, jos samaa riviä klikataan uudelleen
    } else {
      setShowUsers(clientId); // Näytä valitun rivin käyttäjät
    }
  };
  const usersDiv = 'font-medium w-1/3 justify-between gap-4 ';
  const spanTitle = 'font-semibold';
  const spanContent = 'font-normal';
  return (
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <TableHeader />
      <tbody className="text-black">
        {clients.map((client: Client) => (
          <React.Fragment key={client.id}>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer" onClick={() => toggleShowUsers(client.id)}>
              <td className="py-4 px-6">{client.accountID}</td>
              <td className="py-4 px-6">{client.name}</td>
              <td className="py-4 px-6">{client.address}</td>
              <td className="py-4 px-6">{client.email}</td>
              <td className="py-4 px-6">{client.phone}</td>
              <td className="py-4 px-6">{client.vatNumber}</td>
              <td className="py-4 px-6">
                <button
                  className="font-semibold text-blue-600 dark:text-blue-500 hover:underline"
                  onClick={(e) => {
                    e.stopPropagation(); // Estä tapahtuman siirtyminen vanhemmalle komponentille
                    handleInviteReseller(client);
                  }}
                >
                  Kutsu käyttäjä
                </button>
              </td>
            </tr>
            {showUsers === client.id && (
              <tr className="bg-gray-100 dark:bg-gray-700">
                <td colSpan={7} className="py-4 px-6">
                  <h3 className="font-semibold text-lg mb-2">Käyttäjät</h3>
                  {client.users.length > 0 ? (
                    <ul>
                      {client.users.map((user) => (
                        <li key={user.id} className="mb-1 w-full flex ">
                          <div className={usersDiv}>
                            <span className={spanTitle}>Nimi:</span>
                            <span className={spanContent}> {user.name}</span>
                          </div>
                          <div className={usersDiv}>
                            <span className={spanTitle}> Sähköposti:</span> <span className={spanContent}> {user.email} </span>
                          </div>
                          <div className={usersDiv}>
                            <span className={spanTitle}> Puhelin:</span> <span className={spanContent}> {user.phone} </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>Ei käyttäjiä</p>
                  )}
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
