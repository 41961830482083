import React, { useState, useEffect } from 'react';
import { Input } from '@material-tailwind/react';
import EditIcon from '@mui/icons-material/Edit';
import { Typography } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useAuth } from '../../../../../context/authContext';
import { useToast } from '../../../../../context/toastContext';
import './style.css';
const UserInput = ({ label, value, disabled, target, id }: { label: string; value: string | null; disabled?: boolean; target: string; id: number }) => {
  const [edit, setEdit] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const [data, setData] = useState({});
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  useEffect(() => {
    if (value !== newValue) {
      setNewValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // This function is called when the input changes. It updates the local state.
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value);
    setData({ [target]: e.target.value });
  };

  // This function is called when the form is submitted.
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the form from submitting in the traditional way
    setLoading(true); // Set loading to true
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/update-user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${await currentUser.getIdToken()}`,
      },
      body: JSON.stringify({ id: id, data: data }),
    });

    if (response.status === 200) {
      setLoading(false); // Set loading to false
      setEdit(false); // Exit edit mode
      return showToast('Tiedot päivitetty', { type: 'success' });
    }
    setLoading(false); // Set loading to false
    showToast('Tietojen päivitys epäonnistui', { type: 'error' });
    setEdit(false); // Exit edit mode
  };
  const sx = {
    fontFamily: 'poppins',
    fontSize: '1rem',
    fontWeight: 600,
    color: '#5c5c5c',
  };

  return (
    <div className="w-full justify-center">
      <form className="flex flex-row  justify-center items-center  p-2" onSubmit={handleSubmit}>
        <div className="min-w-[175px]">
          <Typography sx={sx}>{label}:</Typography>
        </div>
        <div className="w-3/5">
          <InputStyled label={label} target={target} newValue={newValue} edit={edit} handleChange={handleChange} disabled={disabled} />
        </div>
        <div className="min-w-[80px] justify-end">
          <EditButtons edit={edit} loading={loading} disabled={disabled} setEdit={setEdit} setNewValue={setNewValue} value={value} />
        </div>
      </form>
    </div>
  );
};

export default UserInput;

const InputStyled = ({
  label,
  target,
  newValue,
  edit,
  handleChange,
  disabled,
}: {
  label: string;
  target: string;
  newValue: string | null;
  edit: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}) => {
  return (
    <Input
      label={label}
      crossOrigin="true"
      variant="standard"
      type="text"
      name={target}
      disabled={!edit || disabled}
      color="light-blue"
      style={{
        fontFamily: 'open-sans',
        fontSize: '1rem',
        fontWeight: 600,
        color: '#5c5c5c',
        width: '100%', // Adjusted for full width on smaller screens
      }}
      labelProps={
        !edit
          ? {
              style: {
                color: 'white',
                fontSize: '0.875rem',
                fontWeight: 600,
              },
            }
          : {
              style: {
                color: '#03A9F4',
                fontSize: '0.825rem',
                fontWeight: 600,
              },
            }
      }
      value={newValue || ''}
      onChange={handleChange}
      className="disabled:cursor-default disabled:bg-inherit w-full "
    />
  );
};
const EditButtons = ({
  edit,
  loading,
  disabled = false,
  setEdit,
  setNewValue,
  value,
}: {
  edit: boolean;
  loading: boolean;
  disabled?: boolean;
  setEdit: (value: boolean) => void;
  setNewValue: (value: string | null) => void;
  value: string | null;
}) => {
  return (
    <div className="justify-center items-center flex gap-2 ">
      {!disabled && (
        <>
          {edit ? (
            <>
              {loading ? (
                <p>Ladataan...</p>
              ) : (
                <>
                  <button>
                    <ClearIcon
                      color="error"
                      onClick={() => {
                        setEdit(false);
                        setNewValue(value);
                      }}
                      className="flip-2-hor-top-1 ease-in-out "
                      style={{
                        cursor: 'pointer',
                        marginRight: '10px',
                        height: '25px',
                        width: '25px',
                      }}
                    />
                  </button>
                  <button
                    type="submit"
                    style={{
                      background: 'transparent',

                      cursor: 'pointer',
                    }}
                    className="flip-2-hor-top-1 ease-in-out "
                  >
                    <CheckIcon
                      color="success"
                      style={{
                        cursor: 'pointer',
                        marginRight: '10px',
                        height: '30px',
                        width: '30px',
                      }}
                    />
                  </button>
                </>
              )}
            </>
          ) : (
            <EditIcon onClick={() => setEdit(true)} style={{ cursor: 'pointer' }} className="flip-2-hor-top-1 ease-in-out " />
          )}
        </>
      )}
    </div>
  );
};
