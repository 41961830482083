import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/authContext';
import { CSSTransition } from 'react-transition-group';
import Register from './RegisterProcess/createAccount';
import VerifyEmail from './RegisterProcess/VerifyEmail';
/* import AddBillingInfo from './RegisterProcess/AddBillingInfo'; */
import './register.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AddBillingInfo from './RegisterProcess/AddBillingInfo';

const RegisterProcess = () => {
  const params = useSearchParams();
  const inviteCode = params[0].get('invite');
  const { currentUser, verifiedEmail, userBilling, verifiedUser, checkInvitationIsValid, user } = useAuth();
  const [registrationCompleted, setRegistrationCompleted] = useState(false);

  const navigate = useNavigate();

  let content;
  let key;
  if (inviteCode) {
    checkInvitationIsValid(inviteCode);
  }

  if (!currentUser) {
    content = <Register />;
    key = 'register';
  } else if (currentUser && !verifiedEmail) {
    content = <VerifyEmail />;
    key = 'verifyEmail';
  } else if (currentUser && verifiedEmail && !userBilling) {
    content = <AddBillingInfo />;
    key = 'addBillingInfo';
  } else if (verifiedUser && verifiedEmail && userBilling) {
    content = <div className="justify-center items-center">Rekisteröinti valmis!</div>;
    key = 'completed';
    if (!registrationCompleted) setRegistrationCompleted(true);
  }

  useEffect(() => {
    if (registrationCompleted) {
      const timer = setTimeout(() => {
        if (user?.role === 'RESELLER') {
          navigate('/reseller');
        } else if (user?.role === 'ADMIN') {
          navigate('/admin');
        } else {
          navigate('/dashboard');
        }
      }, 2000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationCompleted, navigate]);

  return (
    <CSSTransition in={content !== undefined} timeout={2000} classNames="fade" unmountOnExit key={key}>
      <div className="flex h-full w-full justify-center items-center ">{content}</div>
    </CSSTransition>
  );
};

export default RegisterProcess;
